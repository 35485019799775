
import React, { useRef } from "react";

import { Bar, Radar, Doughnut } from "react-chartjs-2";
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';


export const RiskDoughut = ({datas, width}) => {
  const chartRef = useRef(null);

  const doughutOptions =  {
    legend:{
      display:false
    },
    tooltips: {
      enabled: false
   },
    maintainAspectRatio: width ? false : true,
  }
  var percMax = parseInt(datas)

  const data = {
    labels: [
      'High risk',
      'Low risk',
    ],
    datasets: [{
      label: 'Risk Doughut',
      data: [percMax, 100-percMax],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
      ],
      hoverOffset: 4
    }]
  };

  // const base64Image2 = chartRef?.current?.toBase64Image();
  // console.log('base64Image2', base64Image2)

  return (
    <div>
      <Doughnut data={data} 
      options={doughutOptions} 
      width={width}
      ref={chartRef}
       />
    </div>
  );
};


const colors = [
  '#374649', '#8bd5ab', '#fe9669', '#5f6b6d', '#01b8aa', 
  'rgb(255, 99, 132)', '#8000ff', '#ff8000', '#7474b0', 
  '#00ffff', '#00ff80', '#ffff00',  '#ff0000', '#ffb2b2', '#cc8e8e', 'rgb(54, 162, 235)',
]

const backgroundColors = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(255, 159, 64, 0.2)',
  'rgba(255, 205, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(201, 203, 207, 0.2)',
  'rgba(1, 184, 170, 0.2)'
]
const borderColors = [
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
  'rgb(1, 184, 170)'
]

export const BarChart = ({title, labels, datas, control}) => {
  const options = {
    scales: {
      // x: {
      //   grid: {
      //     display: false,
      //   },
      //   ticks:{display:true},
      // },
      y: {
        grid: {
          display: false
        }
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        maxHeight: 80,
        labels: {
          font: {
            size: window.innerWidth > 500 ? 20 : 12,
          },
        },
      },
      title: {
        display: false,
        text: title,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 10,
      },
    },
  };
  
  var datasets0 = []
  labels.push('Risk threshold')
  datas.push(control)
  for(var i=0;i<datas.length;i++){
    var t = {
      label: labels[i],
      data: [datas[i]],
      backgroundColor: backgroundColors[i],
      borderColor: borderColors[i],
      borderWidth: 1,
      barPercentage: 0.7,
      borderRadius: 5,

      datalabels: {
        color: 'black',
        font: {
          size: window.innerWidth > 500 ? 20 : 16,
        },
        anchor:'end',
        align:'top',
        formatter: (value) => value + '%',
      },
    }
    datasets0.push(t)
  }

  const data = {
    labels: [''],
    datasets: datasets0
  }

  return <Bar options={options} plugins={[ChartDataLabels]} data={data} />;
}

