import * as yup from 'yup';
import NumericInput from 'react-numeric-input';
import Switch from "react-switch";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { BsLinkedin } from 'react-icons/bs';
import { IconContext } from "react-icons";
import { Card, CardBody, Image } from 'react-bootstrap';
import { Container, Row, Col } from "react-bootstrap";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { globalStyles, ICON_SIZE } from "../styles/global";
import { DescriptionModal } from "../pages/modals/descriptionmodal";
import { Description, ImagesLinks } from "./helpers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import React, { useState } from 'react';


export var variableDict = {
  "age": "Age",
  "ccsAnginaPectoris": "Angina pectoris",
  "historyCerebralBleeding": "History of cerebral bleeding",
  "historyCerebralTia": "History of cerebral transient ischemic attack",
  "historyValvuloplasty": "History of valvuloplasty",
  "mi": "History of myocardial infarction",
  "sts": "Society of Thoracic Surgeons score (%)",
  "pad": "Peripheral artery disease",
  "copd": "Chronic obstructive pulmonary disease",
  "creatinin": "Creatinin",
  "hr": "Heart rate (/minute)",
  "medipre": "Takes any medication",
  "diureticPre": "Diuretic treatment",
  "calciumAntagonistPre": "Calcium antagonist treatment",
  "medicombipre": "Antithrombotics before TAVI",
  "aorticAreaEcho": "Aortic area by TTE (cm2)",
  "regurgaortic": "Aortic regurgitation grade pre-TAVI",
  "regurgmitral": "Mitral regurgitation grade",
  "aorticAngulation": "Aortic angulation (°)",
  "ascendingAortaDiameter": "Ascending aorta diameter (mm)",
  "leftCoronaryHeight": "Left coronary height (mm)",
  "aorticArea": "Aortic valve area (mm2)",
  "aorticAnnulusPerimeter": "Aortic valve perimeter (mm)",
  "typeAorticValveAnatomy": "Type of aortic valve anatomy",
  "locationRaphe": "Location of raphe (bicuspid)",
  "rccCalVol": "Right coronary cusp calcification volume (mm3)",
  "lccCalVol": "Left coronary cusp calcification volume (mm3)",
  "nccAvCalVol": "Non coronary cusp calcification volume (mm3)",
  "aorticValveCalVol": "Aortic valve calcification volume (mm3)",
  "lccDlzCalVol": "Left coronary cusp device landing zone calcification volume (mm3)",
  "rccDlzCalVol": "Right coronary cusp device landing zone calcification volume (mm3)",
  "nccDlzCalVol": "Non coronary cusp device landing zone calcification volume (mm3)",
  "porcelainAorta": "Thoracic aorta calcification volume (mm3)",
  "procedureLocation": "Procedure in hybrid room",
  "anesthesiaType": "Local anesthesia",
  "sheathSize": "Sheath size (mm)",
  "procaccessmain1": "Main access",
  "balloonValvuloplasty": "Balloon valvuloplasty (procedure)",
  "reposition": "Reposition",
  "postARGrade": "Aortic regurgitation grade post-TAVI",
  "contrastVolume": "Contrast volume (mL)",
  "concomittantLaaPfo": "Concomitant LAA/PFO procedure",
  "concomittantIlioFemoralStenting": "Concomitant ilio-femoral stenting",
  "concomittantPci": "Concomitant PCI",
  "newAf": "New atrial fibrillation (complication)",
  "moreOneThv": "Multiple valves necessary",
  "femoralStenting": "Femoral stenting (complication)",
  "hematoma": "Hematoma (complication)",
  "vascularAccessStenosis": "Vascular access stenosis (complication)",
  "accessSiteClosureFailure": "Access site closure failure (complication)",
  "fistula": "Fistula (complication)",
  "complicationSurgery": "Complication requiring surgery (complication)",
  "ventricularPerforation": "Ventricular perforation (complication)",
  "tamponnade": "Tamponade (complication)",
  "conversionSavr": "Conversion to SAVR (complication)",
  "aorticDissection": "Aortic dissection (complication)",
  "instability": "Shock (complication)",
  "instabilityEcmo": "Instability requiring ECMO (complication)",
  "instabilityAssitance": "Instability requiring assistance (complication)",
  "rightCoronaryHeight": "Right coronary height (mm)",
  "device": "Device planned for the procedure",
  "cerprotectionyn": "Cerebral protection",
}

export const variableDictVals = {
  "ccsAnginaPectoris": {0: 'None', 1: 'I', 2: 'II', 3: 'III', 4: 'IV'}, 
  "historyCerebralBleeding": {0: 'No', 1: 'Yes'}, 
  "historyCerebralTia": {0: 'No', 1: 'Yes'}, 
  "historyValvuloplasty": {0: 'No', 1: 'Yes'}, 
  "mi": {0: 'No', 1: 'Yes'}, 
  "pad": {0: 'No', 1: 'Yes'}, 
  "copd": {0: 'No', 1: 'Yes'}, 
  "medipre": {0: 'No', 1: 'Yes'}, 
  "diureticPre": {0: 'No', 1: 'Yes'}, 
  "calciumAntagonistPre": {0: 'No', 1: 'Yes'}, 
  "medicombipre": {1: 'ASA M', 2: 'Clp. M', 5: 'OA M', 6: 'DAPT', 7: 'ASA+OA', 8: 'Clp.+OA', 10: 'Tica+OA', 11: 'DAPT+OA', 12: 'None'}, 
  "regurgaortic": {1: 'None', 2: 'Mild', 3: 'Moder.', 4: 'Severe'}, 
  "regurgmitral": {1: 'None', 2: 'Mild', 3: 'Moder.', 4: 'Severe'}, 
  "typeAorticValveAnatomy": {3: 'Tric.', 0: 'Bic. 0', 1: 'Bic. 1', 2: 'Bic. 2', 4: 'FBic.', 5: 'VIV'}, 
  "locationRaphe": {0: 'None', 1: 'RC-NC', 2: 'LC-NC', 3: 'RC-LC'}, 
  "procedureLocation": {0: 'No', 1: 'Yes'}, 
  "anesthesiaType": {0: 'No', 1: 'Yes'}, 
  "sheathSize": {14: 14, 16: 16, 18: 18, 19: 19, 20: 20, 21: 21, 22: 22, 24: 24, 26: 26, 32: 32, 33: 33}, 
  "procaccessmain1": {1: 'Femoral', 2: 'Apical', 3: 'Other'}, 
  "balloonValvuloplasty": {0: 'No', 1: 'Yes'}, 
  "reposition": {0: 'No', 1: 'Yes'}, 
  "postARGrade": {1: 'None', 2: 'Mild', 3: 'Moder.', 4: 'Severe'}, 
  "concomittantLaaPfo": {0: 'No', 1: 'Yes'}, 
  "concomittantIlioFemoralStenting": {0: 'No', 1: 'Yes'}, 
  "concomittantPci": {0: 'No', 1: 'Yes'}, 
  "newAf": {0: 'No', 1: 'Yes'}, 
  "moreOneThv": {0: 'No', 1: 'Yes'}, 
  "femoralStenting": {0: 'No', 1: 'Yes'}, 
  "hematoma": {0: 'No', 1: 'Yes'}, 
  "vascularAccessStenosis": {0: 'No', 1: 'Yes'}, 
  "accessSiteClosureFailure": {0: 'No', 1: 'Yes'}, 
  "fistula": {0: 'No', 1: 'Yes'}, 
  "complicationSurgery": {0: 'No', 1: 'Yes'}, 
  "ventricularPerforation": {0: 'No', 1: 'Yes'}, 
  "tamponnade": {0: 'No', 1: 'Yes'}, 
  "conversionSavr": {0: 'No', 1: 'Yes'}, 
  "aorticDissection": {0: 'No', 1: 'Yes'}, 
  "instability": {0: 'No', 1: 'Yes'}, 
  "instabilityEcmo": {0: 'No', 1: 'Yes'}, 
  "instabilityAssitance": {0: 'No', 1: 'Yes'}
}

export const elementsOrder = [
  'cerprotectionyn', 'typeAorticValveAnatomy', 'porcelainAorta', 'device',
  'age', 'aorticAngulation', 'ascendingAortaDiameter', 'rightCoronaryHeight', 'leftCoronaryHeight', 
  'aorticArea', 'aorticAnnulusPerimeter', 'rccCalVol', 'lccCalVol', 
  'nccAvCalVol', 'rccDlzCalVol', 'lccDlzCalVol', 'nccDlzCalVol',
]

export var variableDict1 = {
  "age": "Age",
  "aorticAngulation": "Aortic angulation (°)",
  "ascendingAortaDiameter": "Ascending aorta diameter (mm)",
  "rightCoronaryHeight": "Right coronary height (mm)",
  "leftCoronaryHeight": "Left coronary height (mm)",
  "aorticArea": "Aortic valve area (mm2)",
  "aorticAnnulusPerimeter": "Aortic valve perimeter (mm)",
  "typeAorticValveAnatomy": "Type of aortic valve anatomy",
  "rccCalVol": "Right coronary cusp calcification volume (mm3)",
  "lccCalVol": "Left coronary cusp calcification volume (mm3)",
  "nccAvCalVol": "Non coronary cusp calcification volume (mm3)",
  // "aorticValveCalVol": "Aortic valve calcification volume (mm3)",
  "rccDlzCalVol": "Right coronary cusp device landing zone calcification volume (mm3)",
  "lccDlzCalVol": "Left coronary cusp device landing zone calcification volume (mm3)",
  "nccDlzCalVol": "Non coronary cusp device landing zone calcification volume (mm3)",
  "porcelainAorta": "Porcelain aorta",
  "device": "Device planned for the procedure",
  "cerprotectionyn": "Cerebral protection",
}

export const variableDictVals1 = {
  "typeAorticValveAnatomy": {3: 'Tricuspid', 0: 'Bicuspid type 0', 1: 'Bicuspid type 1', 2: 'Bicuspid type 2', 4: 'Functional bicuspid', 5: 'Valve-in-valve'}, 
  "porcelainAorta": {0: 'No', 1: 'Yes'},
  "cerprotectionyn": {0: 'No', 1: 'Yes'},
  "device": {0: 'Sapien 3', 1: 'Sapien ULTRA', 3: 'Sapien THV/XT', 13: 'Centera', 2: 'Corevalve', 4: 'Evolut R', 5: 'Evolut PRO', 8: 'Evolut PRO+', 7: 'Lotus', 12: 'Lotus Edge', 9: 'NAVITOR', 10: 'Portico', 6: 'Acurate Neo', 11: 'Acurate Neo2'}, 

}

export const initialValuesPreset = {
  accessSiteClosureFailure: 0,
  age:80,
  anesthesiaType:1, //yes is local vs no general here
  aorticAngulation:46,
  aorticArea: 431,
  aorticAreaEcho:0.6,
  aorticAnnulusPerimeter:78,
  aorticDissection:0,
  aorticValveCalVol:164,
  ascendingAortaDiameter:32,
  aspirinPre:1,
  balloonValvuloplasty:1,
  calciumAntagonistPre:0,
  ccsAnginaPectoris:0,
  clopidogrel:0,
  complicationSurgery: 0,
  concomittantLaaPfo: 0,
  concomittantIlioFemoralStenting: 0,
  concomittantPci: 0,
  contrastVolume: 70,
  conversionSavr: 0,
  copd: 0,
  creatinin: 70,
  diureticPre: 1,
  instability: 0,
  instabilityEcmo: 0,
  instabilityAssitance: 0,
  femoralStenting: 0,
  fistula: 0,
  hr: 72,
  hematoma: 0,
  historyCerebralBleeding: 0,
  historyCerebralTia: 0,
  historyValvuloplasty: 0,
  leftCoronaryHeight: 15,
  lccCalVol: 52,
  rccCalVol: 52,
  lccDlzCalVol: 55,
  locationRaphe: 0,
  procaccessmain1: 1,
  medicombipre: 1,
  mi: 0,
  moreOneThv: 0,
  otherAccess: 1, 
  nccAvCalVol: 72,
  nccDlzCalVol: 86,
  newAf: 0,
  reposition: 0,
  p2y12Pre: 0,
  pad: 0,
  medipre: 1,
  porcelainAorta: 0,
  procedureLocation: 1,
  postARGrade: 2,
  regurgaortic: 2,
  regurgmitral: 2,
  rccDlzCalVol: 51,
  sheathSize: 18,
  sts: 4.5,
  tamponnade: 0,
  totalDlzCalVol: 150,
  typeAorticValveAnatomy: 3,
  vascularAccessStenosis: 0,
  ventricularPerforation: 0,
  rightCoronaryHeight: 17,
  device: 0,
  cerprotectionyn: 0,
}

export const CalcFormSchema = yup.object ({
  accessSiteClosureFailure: yup.number ().required("This item is required.").nullable(),
  age: yup.number ().required("Age is required.").nullable(),
  anesthesiaType: yup.number ().required("This item is required.").nullable(),
  aorticAngulation: yup.number ().required("This item is required.").nullable(),
  aorticArea: yup.number ().required("Aortic area is required.").nullable(),
  aorticAreaEcho: yup.number ().required("Aortic area by TTE is required.").nullable(),
  aorticAnnulusPerimeter: yup.number ().required("Aortic perimeter is required.").nullable(),
  aorticDissection: yup.number ().required("This item is required.").nullable(),
  aorticValveCalVol: yup.number ().required("Aortic area is required.").nullable(),
  ascendingAortaDiameter: yup.number ().required("Aortic area is required.").nullable(),
  aspirinPre: yup.number ().required("This item is required.").nullable(),
  balloonValvuloplasty: yup.number ().required("This item is required.").nullable(),
  calciumAntagonistPre: yup.number ().required("This item is required.").nullable(),
  ccsAnginaPectoris: yup.number ().required("This item is required.").nullable(),
  clopidogrel: yup.number ().required("This item is required.").nullable(),
  complicationSurgery: yup.number ().required("This item is required.").nullable(),
  concomittantLaaPfo: yup.number ().required("This item is required.").nullable(),
  concomittantIlioFemoralStenting: yup.number ().required("This item is required.").nullable(),
  concomittantPci: yup.number ().required("This item is required.").nullable(),
  contrastVolume: yup.number ().required("Aortic area is required.").nullable(),
  conversionSavr: yup.number ().required("This item is required.").nullable(),
  copd: yup.number ().required("This item is required.").nullable(),
  creatinin: yup.number ().required("This item is required.").nullable(),
  diureticPre: yup.number ().required("This item is required.").nullable(),
  femoralStenting: yup.number ().required("This item is required.").nullable(),
  fistula: yup.number ().required("This item is required.").nullable(),
  instability: yup.number ().required("This item is required.").nullable(),
  instabilityEcmo: yup.number ().required("This item is required.").nullable(),
  instabilityAssitance: yup.number ().required("This item is required.").nullable(),
  hematoma: yup.number ().required("This item is required.").nullable(),
  historyCerebralBleeding: yup.number ().required("This item is required.").nullable(),
  historyCerebralTia: yup.number ().required("This item is required.").nullable(),
  historyValvuloplasty: yup.number ().required("This item is required.").nullable(),
  hr: yup.number ().required("Heart rate is required.").nullable(),
  rccCalVol: yup.number ().required("RCC calcification volume is required.").nullable(),
  lccCalVol: yup.number ().required("LCC calcification volume is required.").nullable(),
  lccDlzCalVol: yup.number ().required("LCC DLZ calcification volume is required.").nullable(),
  leftCoronaryHeight: yup.number ().required("Left coronary height is required.").nullable(),
  locationRaphe: yup.number ().required("This item is required.").nullable(),
  procaccessmain1: yup.number ().required("Main access is required.").nullable(),
  medicombipre: yup.number ().required("Number of drugs is required.").nullable(),
  mi: yup.number ().required("This item is required.").nullable(),
  moreOneThv: yup.number ().required("This item is required.").nullable(),
  medipre: yup.number ().required("This item is required.").nullable(),
  nccDlzCalVol: yup.number ().required("Aortic area is required.").nullable(),
  nccAvCalVol: yup.number ().required("Aortic area is required.").nullable(),
  newAf: yup.number ().required("This item is required.").nullable(),
  otherAccess: yup.number ().required("This item is required.").nullable(),
  p2y12Pre: yup.number ().required("This item is required.").nullable(),
  pad: yup.number ().required("This item is required.").nullable(),
  porcelainAorta: yup.number ().required("Aortic calcification volume is required.").nullable(),
  procedureLocation: yup.number ().required("This item is required.").nullable(),
  postARGrade: yup.number ().required("Aortic area is required.").nullable(),
  regurgaortic: yup.number ().required("This item is required.").nullable(),
  regurgmitral: yup.number ().required("This item is required.").nullable(),
  rccDlzCalVol: yup.number ().required("LCC DLZ calcification volume is required.").nullable(),
  reposition: yup.number ().required("This item is required.").nullable(),
  sheathSize: yup.number ().required("Sheath size is required.").nullable(),
  sts: yup.number ().required("Aortic area is required.").nullable(),
  tamponnade: yup.number ().required("This item is required.").nullable(),
  totalDlzCalVol: yup.number ().required("This item is required.").nullable(),
  typeAorticValveAnatomy: yup.number ().required("Aortic anatomy is required.").nullable(),
  vascularAccessStenosis: yup.number ().required("This item is required.").nullable(),
  ventricularPerforation: yup.number ().required("This item is required.").nullable(),
  rightCoronaryHeight: yup.number ().required("Right coronary height is required.").nullable(),
});

export const elementsOrderMortality = ['inclAge', 'inclMale1', 'preImc', 'histDiabte', 'histAtcdChircardAutre', 'histArtriopathiepriphrique', 'histInsuffisancerespiratoire',  'histRductionsvredelamobilit', 'indicDeuxoap', 'indicSiouinyha', 'histTatcritiquepropratoire', 'histDegrdurgence', 'histCreat', 'preTerritStenose',
'preHtap1', 'preGradientmoy1', 'preFractiondjection', 'preGradfuiteaortique1', 'preGradfuitemitrale1', 'procAbord', 'procTypedelavalve1', 'procDiamtredelavalve1',  'histFibrillationauriculaire', 'histAitavc', 'histAngioplastiecoronaire', 'histPontagecoronaire', 'histSiouiinsuline', 'histPacemaker']

export const initialValuesPresetMortality = {
  inclAge: 75,
  inclMale1: 0,
  preImc: 25,
  histDiabte: 0,
  histAtcdChircardAutre: 0,
  histArtriopathiepriphrique: 0,
  histInsuffisancerespiratoire: 0,
  histRductionsvredelamobilit: 0,
  indicDeuxoap: 0,
  indicSiouinyha: 2,
  histTatcritiquepropratoire: 0,
  histDegrdurgence: 0,
  histCreat: 90,
  preTerritStenose: 0,
  preHtap1: 0,
  preGradientmoy1: 40,
  preFractiondjection: 60,
  preGradfuiteaortique1: 0,
  preGradfuitemitrale1: 0,
  procAbord: 0,
  procTypedelavalve1: 0,
  procDiamtredelavalve1: 26,
  histFibrillationauriculaire: 0,
  histAitavc: 0,
  histAngioplastiecoronaire: 0,
  histPontagecoronaire: 0,
  histSiouiinsuline: 0,
  histPacemaker: 0
}

export const MortFormSchema = yup.object ({
  inclAge: yup.number ().required("This item is required.").nullable(),
  inclMale1: yup.number ().required("This item is required.").nullable(),
  preImc: yup.number ().required("This item is required.").nullable(),
  histDiabte: yup.number ().required("This item is required.").nullable(),
  histAtcdChircardAutre: yup.number ().required("This item is required.").nullable(),
  histArtriopathiepriphrique: yup.number ().required("This item is required.").nullable(),
  histInsuffisancerespiratoire: yup.number ().required("This item is required.").nullable(),
  histRductionsvredelamobilit: yup.number ().required("This item is required.").nullable(),
  indicDeuxoap: yup.number ().required("This item is required.").nullable(),
  indicSiouinyha: yup.number ().required("This item is required.").nullable(),
  histTatcritiquepropratoire: yup.number ().required("This item is required.").nullable(),
  histDegrdurgence: yup.number ().required("This item is required.").nullable(),
  histCreat: yup.number ().required("This item is required.").nullable(),
  preTerritStenose: yup.number ().required("This item is required.").nullable(),
  preHtap1: yup.number ().required("This item is required.").nullable(),
  preGradientmoy1: yup.number ().required("This item is required.").nullable(),
  preFractiondjection: yup.number ().required("This item is required.").nullable(),
  preGradfuiteaortique1: yup.number ().required("This item is required.").nullable(),
  preGradfuitemitrale1: yup.number ().required("This item is required.").nullable(),
  procAbord: yup.number ().required("This item is required.").nullable(),
  procTypedelavalve1: yup.number ().required("This item is required.").nullable(),
  procDiamtredelavalve1: yup.number ().required("This item is required.").nullable(),
  histFibrillationauriculaire: yup.number ().required("This item is required.").nullable(),
  histAitavc: yup.number ().required("This item is required.").nullable(),
  histAngioplastiecoronaire: yup.number ().required("This item is required.").nullable(),
  histPontagecoronaire: yup.number ().required("This item is required.").nullable(),
  histSiouiinsuline: yup.number ().required("This item is required.").nullable(),
  histPacemaker: yup.number ().required("This item is required.").nullable(),
});


export const NumericInputInForm = ({label, formikKey, changedVariables, 
  creatininMgdl, ToggleSwitchCreatininMgdl, 
  props, ...rest}) => {
  const [valueChanged, setValueChanged] = useState(changedVariables.includes(formikKey))
  const [show, setShow] = useState(false)
  const ToggleShow = () =>{
    setShow(!show)
  }

  const DescriptionButton = () => {
    if(Description[label]){
      return(
        <span style={{ fontWeight:'bold', cursor:'pointer', marginLeft:2, marginBottom:5, }}
        onClick={ToggleShow}
        >
        <IconContext.Provider value={{ color: 'black', size:ICON_SIZE -10 }}>
          <AiOutlineQuestionCircle />
        </IconContext.Provider>
      </span>
          )
      }
    }

  const inputStyles = {...globalStyles.textNormal}
  if (props.touched[formikKey] && props.errors[formikKey]) {
    inputStyles.borderColor = 'red';
  }
  return (
  <div style={{ width:'100%', padding:2, marginVertical:4, borderRadius:15, 
    backgroundColor: valueChanged ? '#7fbf7f' : '#f4f4f4', 
    }}
    >
    <div style={{ paddingVertical:3, paddingHorizontal:5, }}>
      <div style={{ flexDirection: 'row', alignItems:'center', }}>
        <span style={[globalStyles.textDescription]}>{label}</span>
        <DescriptionButton />

      {formikKey === 'creatinin' ? 
        <div style={{ flexDirection:'row', marginLeft:30, justifyContent:'center', alignItems:'center', }}>
        <div style={{ fontWeight: creatininMgdl ? 'normal' : 'bold', 
          fontFamily:'proximanova-regular', fontSize:15, marginRight:5, }}>μmol/L</div>
        <label className="custom-toggle mt-1" >
          <Switch 
            onChange={ToggleSwitchCreatininMgdl} 
            checked={creatininMgdl} 
            offColor='#1972D2'
            uncheckedIcon={false}
            onColor='#7919D2'
            checkedIcon={false}
            height={24}
            width={50}
            // style={{ marginHorizontal:10 }}
          />
        </label>
        <div style={{ fontWeight: creatininMgdl ? 'bold' : 'normal', 
          fontFamily:'proximanova-regular', fontSize:15, marginLeft:5, }}>mg/dL</div>
        </div>
      : null }

      </div>
      <DescriptionModal show={show} text={Description[label]} image={ImagesLinks[label]} />

      <NumericInput 
      name={formikKey}
      step={1} 
      precision={1}
      min={0}
      style={{ input: {fontFamily:'proximanova-regular', fontSize:15, 
      textAlign:'center', padding:5,  width:'100%',
      borderRadius: 15, backgroundColor:'#fcfcfc'},
      }}
      placeholder={label}
      placeholderTextColor="grey"
      type={"number"}
      mobile={true}
      onChange={v => {
        props.setFieldValue(formikKey, v)
        setValueChanged(true)
        }}
      value={props.values[formikKey]}
      onBlur={props.handleBlur (formikKey)}
      {...rest}
      />
      <span style={globalStyles.errorText}>
        {props.touched[formikKey] && props.errors[formikKey]}
      </span>
    </div>
  </div>
  )
}

export const BinaryInput = ({label, formikKey, changedVariables, props, }) => {
  const [valueChanged, setValueChanged] = useState(changedVariables.includes(formikKey))

  const initChecked = props.values[formikKey] === 1 ? true : false
  const [checked, setChecked] = useState(initChecked)
  const ToggleSwitch = () => {
    var v = checked === false ? 1 : 0
    setChecked(!checked)
    props.setFieldValue(formikKey, v)
    setValueChanged(true)
  }

  const [show, setShow] = useState(false)
  const ToggleShow = () =>{
    setShow(!show)
  }

  const DescriptionButton = () => {
    if(Description[label]){
      return(
        <span style={{ fontWeight:'bold', cursor:'pointer', marginLeft:2, marginBottom:5, }}
        onClick={ToggleShow}
        >
        <IconContext.Provider value={{ color: 'black', size:ICON_SIZE -10 }}>
          <AiOutlineQuestionCircle />
        </IconContext.Provider>
      </span>
          )
      }
    }

  return (
    <div style={{ width:'100%', padding:2, marginVertical:4,
    backgroundColor: valueChanged ? '#7fbf7f' : '#f4f4f4', borderRadius:15 
     }}>
      <div style={{ paddingVertical:3, paddingHorizontal:5, justifyContent:'flex-start', }}>
        <div style={{ flexDirection: 'row', alignItems:'center', }}>
          <span style={[globalStyles.textDescription]}>{label}</span>
          <DescriptionButton />
        </div>
        <DescriptionModal show={show} text={Description[label]} image={ImagesLinks[label]} />

        <div style={{ marginHorizontal:5, }}>
          <label className="custom-toggle mt-1">
            <Switch 
            onChange={ToggleSwitch} 
            checked={checked} 
            offColor='#d8d8d8'
            onColor='#1972D2'
            height={24}
            width={50}
            />
          </label>
          <span style={globalStyles.errorText}>
            {props.touched[formikKey] && props.errors[formikKey]}
          </span>
        </div>
      </div>
    </div>
  )
}

export const MultipleChoicePills = ({label, formikKey, changedVariables, props, opts, optsLabels}) => {
  const [valueChanged, setValueChanged] = useState(changedVariables.includes(formikKey))

  const [circledNavPills, setCircledNavPills] = useState(props.values[formikKey])
  const toggleNavs = (v) => {
    setCircledNavPills(v)
    props.setFieldValue(formikKey, v)
    setValueChanged(true)
  }

  const [show, setShow] = useState(false)
  const ToggleShow = () =>{
    setShow(!show)
  }

  const DescriptionButton = () => {
    if(Description[label]){
      return(
        <span style={{ fontWeight:'bold', cursor:'pointer', marginLeft:2, marginBottom:5, }}
        onClick={ToggleShow}
        >
        <IconContext.Provider value={{ color: 'black', size:ICON_SIZE -10 }}>
          <AiOutlineQuestionCircle />
        </IconContext.Provider>
      </span>
          )
      }
    }

  return (
    <div style={{ width:'100%', padding:2, marginVertical:4,
      backgroundColor: valueChanged ? '#7fbf7f' : '#f4f4f4', borderRadius:15 
      }}
      >
      <div style={{ paddingVertical:3, paddingHorizontal:5, }}>

        <div style={{ flexDirection: 'row', alignItems:'center', }}>
          <span style={[globalStyles.textDescription]}>{label}</span>
          <DescriptionButton />
        </div>
        <DescriptionModal show={show} text={Description[label]} image={ImagesLinks[label]} />

      <Stack direction="row" spacing={1} 
        style={{ flex:1, flexDirection:'row', flexWrap:'wrap',  }}>
        {(()=>{
        return opts.map((item, key) => {
          return (
            <div key={key} >
            <Chip label={optsLabels[opts.indexOf(item)]} color="primary" 
              variant={circledNavPills === item ? "default" : "outlined"} 
              onClick={()=>toggleNavs(item)}
              style={{ margin:1, padding:3, fontSize:14, }}
              size="small"
            />
            </div>
          )}
          )
        })()}
      </Stack>
    </div>
    </div>
  )
}


export const HelpButton = ({ label }) => {
  const [showHelp, setShowHelp] = useState(false);
  
  return (
    <div>
      <DescriptionModal show={showHelp} text={Description['HelpButton']} />
      <div style={{ fontWeight:'bold', cursor:'pointer', marginLeft:2, marginBottom:3 }}
            onClick={() => setShowHelp(!showHelp)}
            >
      <IconContext.Provider value={{ color: 'black', size:ICON_SIZE-10 }}>
        <AiOutlineQuestionCircle />
      </IconContext.Provider>
      </div>
      <span>
      </span>
  </div>
  );
};

export const TestimonialCard = ({ image, name, text }) => {
  return (
    <Card className="card-lift--hover shadow border-0 m-1">
      <Card.Body className="py-3">
        <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Image className="rounded-circle img-fluid shadow shadow-lg--hover m-1" style={{ maxWidth: 100 }} src={"https://www.alviss.ai/static/images/" + image} />
          <h5 className="title">
            <span className="d-block mb-1">{name}</span>
          </h5>
        </div>
        <p className="description mt-3">
          <span style={{ fontSize: 17 }}>{text}</span>
        </p>
        {name === 'Pavel Overtchouk MD' ?
          <a style={{ alignSelf: 'center', }} href="https://www.linkedin.com/in/pavelovertchouk/" target="_blank" rel="noreferrer">
            <IconContext.Provider value={{ size: ICON_SIZE }}>
              <BsLinkedin />
            </IconContext.Provider>
          </a>
          : null}
        <br />
      </Card.Body>
    </Card>
  );
}

export const TestimonialCards = () => {
  return (
    <div>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <Row className="row-grid">
                <h4 className="text-center p-2">World class healthcare technologists team</h4>
                <br />
                <br />
                <Col lg="4">
                  <TestimonialCard image={'22_povertchouk.png'} name={'Pavel Overtchouk MD'} text={'Physician, interventional cardiologist, AI engineer, researcher and full-stack developer. Pavel is the founder and CEO of alviss.ai.'} />
                </Col>
                <Col lg="4">
                  <TestimonialCard image={'tpilgrim.png'} name={'Thomas Pilgrim MD PhD'} text={'Bern University Hospital (Switzerland). Physician, interventional cardiologist, academic. Prof. Pilgrim is an advisor and research lead.'} />
                </Col>
                <Col lg="4">
                  <TestimonialCard image={'tokuno.png'} name={'Taishi Okuno MD'} text={'Mitsui Memorial University Hospital (Japan). Physician, interventional cardiologist, academic. Dr. Okuno is an advisor and research lead.'} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }