import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import {  useMutation } from '@apollo/client';
import { useNavigate, } from "react-router-dom";

import Modal from 'react-modal';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Box, Button } from '@mui/material';

import { UPLOAD_CTPE, } from '../../network/stroke';
import { ICON_SIZE, } from "../../styles/global";
import { AuthContext } from "../../config/auth";
import { ImagesToPhotos, millisToMinutesAndSecondsFormated, modalStylesSmall, notifConfig, } from "../../components/helpers";

import { RoutesNames } from "../../routes";
import { FooterCustom } from "./FooterCustom";

import {useDropzone} from 'react-dropzone'
import { FaExclamationTriangle } from "react-icons/fa";
import { IconContext } from "react-icons";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { AiOutlineCloseCircle, AiOutlineCloudUpload } from "react-icons/ai";

import Lottie from "lottie-react";
import animationClock from '../../lotties/clock_animation.json';
import animationFile1 from '../../lotties/file_animation1.json';
import animationSuccess from '../../lotties/success_animation.json';

import { NavbarInternalPE } from "./NavbarInternalPE";
import { LinearProgressWithLabel } from "../../components/elements";

const notifyInfo = (message) => toast.info(message, {...notifConfig});
const notifyError = (message) => toast.error(message, {...notifConfig});

export const Ctpe = () => {
  const { user, SaveFirstTimeDone } = useContext(AuthContext)

  const [showH, setShowH] = useState(false)
  useEffect(()=>{
    setShowH(true)
  }, [])
  const ToggleShow = () => {setShowH(!showH)}
  const [photos, setPhotos] = useState([]);
  const [index, setIndex] = useState(-1);

  let history = useNavigate();

  const [startTime, setStartTime] = useState(new Date())
  const [sinceStart, setSinceStart] = useState(new Date())
  const [timeShown, setTimeShown] = useState(0)
  const updateClock = (initTime) => {
    var now = new Date();
    var milli = now.getTime() - initTime;
    setSinceStart(milli)
  }


  const [progress, setProgress] = useState(0);
  const INCREMENT = 1

  useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + INCREMENT));
        updateClock(startTime);
      }, 1500); // 2.5 minutes
      return () => {
        clearInterval(timer);
      };
  }, [startTime]);

  useEffect(()=>{
    if(!user){
      history(RoutesNames.Signin.path+'?next=/ctpe');
    }
  }, [user])

  const [errors, setErrors] = useState()
  const elementRef = useRef(null);

  const [uploadCtpeFile, {data:datacv, loading:loadingcv, error:errorcv}] = useMutation(UPLOAD_CTPE);

  const initialResult = []
  const [result, setResult] = useState(initialResult);
  const [message, setMessage] = useState()

  const [patientSelected, setPatientSelected] = useState('')

  const createFile = (bits, name, options) => {
    try {
        return new File(bits, name, options);
    } catch (e) {
        var myBlob = new Blob(bits, options || {});
        myBlob.lastModified = new Date();
        myBlob.name = name;
        return myBlob;
    }
  };


  const MyDropzone = () => {
    const onDrop = useCallback(files => {
      const file = files[0]

      if(!file?.name?.includes('.zip') && !file?.name?.includes('.7z') && !file?.name?.includes('.nii.gz')){
        const errMess = 'Your file does not appear to be compressed or zipped. Please compress or zip your folder (right click on the folder then Compress or Zip). Then upload your .zip or .7z file here!'
        setMessage(errMess)
        notifyError(errMess)
      }else{
        setMessage('')
        var file1 = createFile([file], '_'+file.name, {});
        uploadCtpeFile({ variables: { file:file1 } })
        setPatientSelected(file?.name)
        setResult([])
        setProgress(0)
        setStartTime(new Date())
      }
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  
    return (
      <div {...getRootProps()} 
        style={{ 
          height: 220, 
          cursor: 'pointer', 
          borderRadius: 10,
          backgroundColor: isDragActive ? '#f2f2f2' : 'white', 
          borderStyle: 'dashed',
          borderColor: '#1764c0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <input {...getInputProps()}/>
        {isDragActive ? (
          <p style={{ 
            fontSize: 18, 
            textAlign: 'center', 
            fontWeight: 'bold',
          }}>
            Drop the file ...
          </p>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <IconContext.Provider value={{ color: 'rgb(54, 162, 235)', size: 130 }}>
              <AiOutlineCloudUpload />
            </IconContext.Provider>
            
            <p style={{ 
              color: 'black', 
              fontSize: 18, 
              fontWeight: 'bold',
              marginTop: 10,
              textAlign: 'center'
            }}>
              Drop the CT file (.zip)
            </p>
          </div>
        )}
      </div>
    )
  }


  const FirstTime = () => {
    const [draggedItem, setDraggedItem] = useState(null);
    const [droppedItems, setDroppedItems] = useState([]);
  
    const handleDragStart = () => {
      setDraggedItem(null); // Reset the dragged item
    };
  
    const handleDrop = (item) => {
      setDroppedItems([...droppedItems, item]);
    };
  
    const handleDragOver = (e) => {
      e.preventDefault();
    };
  
    const DraggableItem = ({ item, onDragStart }) => {
      const handleDragStart = (e) => {
        e.dataTransfer.setData('text/plain', item); // Set the data being dragged
        onDragStart();
      };
    
      return (
        <div
          draggable
          onDragStart={handleDragStart}
          style={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            width: '100%', 
            height: '100%' 
          }}
          onClick={() => {
            var file1 = createFile(['file'], '_test_ct.zip', {});
            uploadCtpeFile({ variables: { file:file1 } })
            setPatientSelected('test_ct.zip')
            setResult([])
            setProgress(0)
            setStartTime(new Date())
          }}
        >
          <Lottie 
            animationData={animationFile1} 
            style={{ 
              height: 220, 
              width: 220,
              margin: 'auto'
            }} 
          /> 
        </div>
      );
    };
  
    const DroppableArea = ({ onDrop, onDragOver }) => {
      const handleDrop = (e) => {
        e.preventDefault();
        const data = e.dataTransfer.getData('text/plain'); // Get the data being dropped
        onDrop(data);

        var file1 = createFile(['file'], '_test_ct.zip', {});
        uploadCtpeFile({ variables: { file:file1 } })
        setPatientSelected('test_ct.zip')
        setResult([])
        setProgress(0)
        setStartTime(new Date())

      };
    
      return (
        <div
          onDrop={handleDrop}
          onDragOver={onDragOver}
          onClick={handleDrop}
        >
        <div 
          style={{ 
            height: 220, 
            borderRadius: 10,
            backgroundColor: 'white', 
            borderStyle: 'dashed',
            borderColor: '#1764c0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          >
            <div className="flex flex-col items-center justify-center">
              <IconContext.Provider value={{ color: 'rgb(54, 162, 235)', size: 130 }}>
                <AiOutlineCloudUpload />
              </IconContext.Provider>
              
              <p style={{ 
                color: 'black', 
                fontSize: 18, 
                fontWeight: 'bold',
                marginTop: 10,
                textAlign: 'center'
              }}>
                Drop the CT file
              </p>
            </div>
        </div>
          
        </div>
      );
    };

    return (
        <div>
        <Modal
          isOpen={showH}
          onRequestClose={ToggleShow}
          style={modalStylesSmall}
          contentLabel="Description"
          >
          <div style={{ flex:1, justifyContent:'space-between', }}>
              <div style={{ position:'absolute', top:-10, right:5, zIndex:1000, 
                  backgroundColor:'transparent', }}>
                  <span style={{ padding:10, cursor:'pointer', alignItems:'center', justifyContent:'center', }} 
                  onClick={() => {
                    ToggleShow();
                    SaveFirstTimeDone();
                  }}
                  >
                  <IconContext.Provider value={{ color: 'black', size:ICON_SIZE, }} >
                      <AiOutlineCloseCircle />
                  </IconContext.Provider>
                  </span>
              </div>
              <h4 className="text-center text-2xl" style={{ color:'black', marginTop:10, marginBottom:10, }}>First time using AI PE?</h4>
              {!loadingcv && timeShown === 0 ? <h5 className="text-center" style={{ color:'black', }}>Drop this CT file in the drop zone:</h5> : null}
              {!loadingcv && timeShown === 0 ? <DraggableItem item="test_ct.zip" onDragStart={handleDragStart} /> : null}
              {!loadingcv && timeShown === 0 ? <DroppableArea onDrop={handleDrop} onDragOver={handleDragOver} /> : null}

              {loadingcv ? 
              <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <Lottie animationData={animationClock}  style={{ height:250, width:250, }} /> 
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop:10,  }}>
                  <span> Analyzing {patientSelected} ...</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop:10,  }}>
                  <span>{millisToMinutesAndSecondsFormated(sinceStart)}</span>
                </div>
              </div>
              : null
              }

              {timeShown > 0 ? 
              <div style={{ margin:10, }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <Lottie animationData={animationSuccess}  style={{ height:170, width:170, }} loop={1} /> 
                </div>

                <h4 style={{ textAlign:'center', color:'black', marginTop:10, }}>The report has been generated in <span style={{ fontSize:24, fontWeight:'bold', }}>{millisToMinutesAndSecondsFormated(timeShown)}</span>!</h4>
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
                  <Button
                  color="primary" variant="contained"
                  type="submit"
                  onClick={() => {
                    ToggleShow();
                    }}
                  > 
                    See the report
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
                  <Button
                  color="primary" variant="contained"
                  type="submit"
                  onClick={() => {
                    ToggleShow();
                    }}
                  > 
                      Get a report for your CT in 30-150 seconds
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
                  <Button
                  color="primary" variant="contained"
                  type="submit"
                  onClick={() => history(RoutesNames.Bookdemo.path)}
                  > 
                      Contact our team
                  </Button>
                </Box>
              </div>
              : null}

          </div>
        </Modal>
        </div>
        );
  }


  useEffect(()=>{
    if(datacv?.uploadCtpe){
      setProgress(100)
      setTimeShown(sinceStart)
      var result = JSON.parse(datacv?.uploadCtpe?.result)
      setResult(result)
      var photos = result?.images?.length>0? ImagesToPhotos(result?.images) : []
      setPhotos(photos)
      var mess = datacv?.uploadCtpe?.result?.message
      setMessage(mess ? mess : '')
      if(mess?.length>0){
        notifyInfo(mess)
      }
    }
  }, [datacv])

  useEffect(()=>{
    if(errorcv){
      setProgress(100)
      console.log('errorcv', errorcv)
      if(errorcv?.message?.length>0){
        setErrors(errorcv?.message)
      }
    }
  }, [errorcv])

  useEffect(()=>{
    if(errors?.length>0){
      if(errors.includes('fetch')){
        notifyError('Error connecting to the server. Please try again!')
      } else if(errors.includes('token')) {
        notifyError('Error connecting to the server. Please check your Internet connection and try again!')
      } else if(errors.includes('processing')) {
        notifyError(errors)
      } else {
        notifyError('Sorry, an error occurred. Please try again!')
      }
      setErrors('')
      setPatientSelected('')
    }
  }, [errors])

  return (
    <div>
      {/* <Sidebar/>
      <ToastContainer/>
      <Tracker /> */}

      <main className="content p-0 m-0">
        <NavbarInternalPE />

        <div style={{ padding: 10 }}>
          <FirstTime />
          
          <div style={{ display: 'flex', justifyContent: 'center', padding:20 }}>
            <h2 className="text-center text-2xl" style={{ color:'black' }}>AI PE: look for pulmonary embolism in your CT.</h2>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', paddingBottom:20 }}>
            <h5 style={{ textAlign:'center', color:'#5f6369' }}>Get a your automated PE lookup.</h5>
          </div>

          <br/>

          {!loadingcv ? 
          <div style={{ display: 'flex', justifyContent: 'center', borderRadius:10 }}>
            <MyDropzone />
          </div>
          : null}


          {loadingcv ? (
            <div style={{ marginTop: 40, padding: 10 }}>
              <br/>
              <LinearProgressWithLabel value={progress} />
              
              <div className="flex flex-col items-center justify-center mt-4">
                <Lottie 
                  animationData={animationClock}  
                  style={{ height: 250, width: 250 }} 
                /> 
                
                <span className="mt-2 text-center"> 
                  Analyzing {patientSelected} ...
                </span>
                
                <span className="mt-2 text-center">
                  {millisToMinutesAndSecondsFormated(sinceStart)}
                </span>
              </div>
              <br/>
            </div>
          ) : null}

          {message?.length > 0 && result?.length < 2 ? (
            <div className="flex justify-center items-center gap-2 my-8">
              <IconContext.Provider value={{ color: '#ff6666', size: 40 }}>
                <FaExclamationTriangle/>
              </IconContext.Provider>
              <p className="text-xl text-center p-2">
                {message}
              </p>
            </div>
          ) : null}

          {(()=>{
            if(result?.result?.length>0 && !loadingcv){
              return (
              <div style={{ marginLeft:5, marginTop:20, borderRadius:15, justifyContent:'center' }} >
                <br/>

                <div ref={elementRef}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding:1 }}>
                  <p style={{ fontSize:24, textAlign:'center' }}>
                  PE lookup for <span style={{ fontSize:24, fontWeight:'bold' }}>{patientSelected}</span>
                  </p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding:1 }}>
                  <p style={{ fontSize:24, textAlign:'center' }}>
                    Generated in <span style={{ fontSize:24, fontWeight:'bold' }}>{millisToMinutesAndSecondsFormated(timeShown)}</span>!
                  </p>
                </div>

                <br/>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <p style={{ fontSize:24, textAlign:'center' }}>
                  {result?.result}
                  </p>
                </div>
                <br/>

                <PhotoAlbum photos={photos} layout="rows" targetRowHeight={250} onClick={({ index }) => setIndex(index)} />
                <Lightbox
                  slides={photos}
                  open={index >= 0}
                  index={index}
                  close={() => setIndex(-1)}
                  plugins={[Fullscreen, Zoom]}
                />


                </div>

                <br/>

              </div>
                )
                }

          })()}


          <br/>
          <br/>
          <br/>
          <br/>

          <br/>
          <br/>
          <br/>
          <br/>

          <br/>
          <br/>
          <br/>
          <br/>


          <br/>
          <br/>
          <br/>
          <br/>

          <br/>
          <br/>
          <br/>
          <br/>

          {/* <div style={{ backgroundImage: `url(${ct_chest})`, padding:40, height:150, }}></div> */}

        </div>

        <FooterCustom />
      </main>
    </div>
  );
};
