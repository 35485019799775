import React from "react";
import { useNavigate } from "react-router";

import "react-activity/dist/Sentry.css";
import { Box, Button } from '@mui/material';
import { RoutesNames } from "../routes";
import { elementsOrder } from "./elements";

export const notifConfig = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}


export const BookDemoButton = () => {
  let history = useNavigate()
  return (
  <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
    <Button color="primary" variant="contained" onClick={()=>{
        history(RoutesNames.Bookdemo.path)
      }} > Book a demo
    </Button>
  </Box>
  )
}



export const modalStyles = {
    content: {
      background: 'white',
      outline: 'none',
      width: '100%',
      height:'90%',
      maxWidth: '800px',
      maxHeight: '800px',
      boxShadow: '0 0 34px 0 rgba(0, 0, 0, 0.24)',
      overflowY: 'auto',
      position: 'relative',
      marginRight:50,
      top:0,
      alignSelf:'center',
    },
    overlay: {
      position: 'fixed',
      zIndex: 999999,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.3)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };


  export const modalStylesSmall = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999999,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '550px',
      width: '90%',
      maxHeight: '90vh',
      margin: '20px auto',
      padding: '25px',
      border: 'none',
      background: '#fff',
      borderRadius: '8px',
      outline: 'none',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      overflow: 'auto',
    }
  };



export const Description = {
  'Patient ID': "Please assign any patient number (numbers or characters) and keep it on your end. No identifiable information is kept by our system.",
  'Left coronary cusp calcification volume (mm3)': 'Calcification volume in mm3 of the aortic valve cusp under the left coronary artery',
  'Non coronary cusp calcification volume (mm3)': 'Calcification volume in mm3 of the aortic valve cusp under none of the coronary arteries',
  'Aortic valve calcification volume (mm3)': 'Calcification volume in mm3 of the aortic valve',
  'Left coronary cusp DLZ calcification volume (mm3)': 'Calcification volume in mm3 of the aortic valve cusp and left ventricle outflow tract under the left coronary arteriy regarding the zone of the prosthesis implantation (device landing zone or DLZ)',
  'Right coronary cusp DLZ calcification volume (mm3)': 'Calcification volume in mm3 of the aortic valve cusp and left ventricle outflow tract under the right coronary arteriy regarding the zone of the prosthesis implantation (device landing zone or DLZ)',
  'Non coronary cusp DLZ calcification volume (mm3)': 'Calcification volume in mm3 of the aortic valve cusp and left ventricle outflow tract under none of the coronary arteries regarding the zone of the prosthesis implantation (device landing zone or DLZ)',
  'Thoracic aorta calcification volume (mm3)': 'Calcification volume in mm3 of the ascending aorta',
  'Type of aortic valve anatomy': 'Tric.: tricuspid aortic valve. Bic.: bicuspid aortic valve. Bicuspid valves can be of type 0, 1 and 2. FBic.: functional bicuspid aortic valve. VIV: valve-in-valve.',
  'Antithrombotics before TAVR': 'Antithrombotic pretreatment before the TAVR procedure. ASA M: aspirin monotherapy. ASA+OA: aspirin with oral anticoagulation. Clp.+OA: clopidogrel with oral anticoagulation. DAPT: dual antiplatelet therapy. OA M: oral anticoagulation monotherapy. Tica+OA: ticagrelor with oral anticoagulation.',
  'Balloon valvuloplasty (procedure)': 'Pre-dilatation or valvuloplasty before THV implantation',
  'Left coronary height (mm)': 'Height of the left coronary artery from the aortic valve plane.',
  'Aortic angulation (°)': 'Angle of the aortic valve plane from the horizontal in degrees.',
  'Aortic valve area (mm2)': 'Area of the aortic valve plane.',
  'Aortic valve perimeter (mm)': 'Perimeter of the aortic valve plane.',
  'Ascending aorta diameter (mm)': 'Diameter of the ascending aorta.',
  'Sheath size (mm)': 'Size of the TAVR delivery catheter.',
  'STS score (%)': 'Society of Thoracic Surgerons score (%). The STS score calculator can be accessed at https://riskcalc.sts.org/stswebriskcalc',
  'probability of CVE': 'Percentage of highest MSE (highest risk) observed during algorithm training.',
  'iTAVR Outcomes': 'Cerebrovascular event: refers to stroke or transient ischemic attack. Pacemaker: refers to the need for an implantable pacemaker after TAVR for atrioventicular block or bradycardia. Cardiac technical failure: refers to an unsuccessful or incorrect delivery of the valve or cardiac structural complication, possibly requiring surgery or intervention related to the device. Vascular technical failure: refers to an unsuccessful or incorrect delivery of the valve or vascular complication, possibly requiring vascular surgery or intervention. Definitions are corresponding to those of the VARC-3 guidelines.',
}

export const ImagesLinks = {
  'Left coronary cusp calcification volume (mm3)': 'https://www.alviss.ai/media/images/brand/left coronary cusp calcification.png',
  'Non coronary cusp calcification volume (mm3)': 'https://www.alviss.ai/media/images/brand/non coronary cusp calcification.png',
  'Aortic valve calcification volume (mm3)': 'https://www.alviss.ai/media/images/brand/aortic valve calcification.png',
  'Left coronary cusp DLZ calcification volume (mm3)': 'https://www.alviss.ai/media/images/brand/dlz left calcification.png',
  'Right coronary cusp DLZ calcification volume (mm3)': 'https://www.alviss.ai/media/images/brand/dlz right calcification.png',
  'Non coronary cusp DLZ calcification volume (mm3)': 'https://www.alviss.ai/media/images/brand/dlz non coronary calcification.png',
  'Thoracic aorta calcification volume (mm3)': 'https://www.alviss.ai/media/images/brand/ascending aorta calcification.png',
  'Left coronary height (mm)': 'https://www.alviss.ai/media/images/brand/lc height.png',
  'Aortic angulation (°)': 'https://www.alviss.ai/media/images/brand/aortic angle.png',
  'Aortic valve area (mm2)': 'https://www.alviss.ai/media/images/brand/aortic valve measurement.png',
  'Aortic valve perimeter (mm)': 'https://www.alviss.ai/media/images/brand/aortic valve measurement.png',
  'Ascending aorta diameter (mm)': 'https://www.alviss.ai/media/images/brand/ascending aorta diameter.png',
}


export const PreprocessValues = ({values, concomitant, complications, creatininMgdl}) =>{
  // console.log('PreprocessValues', concomitant, complications, creatininMgdl)
  var elements = []
  for(var i=0;i<elementsOrder.length;i++){
    var t = 0
    var ele = elementsOrder[i]
    if(['aorticAreaEcho', 'sts'].includes(ele)){
      t = values[ele]*100
    } else if(['creatinin'].includes(ele)){
      t = creatininMgdl === true ? values[ele]*88.42 : values[ele]
    } else{
      t = values[ele]
    }
    elements.push(t)
  }
  if(concomitant === false){
    var indexConcomittantLaaPfo = elementsOrder.indexOf('concomittantLaaPfo')
    var indexConcomittantIlioFemoralStenting = elementsOrder.indexOf('concomittantIlioFemoralStenting')
    var indexConcomittantPci = elementsOrder.indexOf('concomittantPci')
    elements[indexConcomittantLaaPfo] = 0
    elements[indexConcomittantIlioFemoralStenting] = 0
    elements[indexConcomittantPci] = 0
  }
  if(complications === false){
    var indexMoreOneThv = elementsOrder.indexOf('moreOneThv')
    var indexFemoralStenting = elementsOrder.indexOf('femoralStenting')
    var indexAorticDissection = elementsOrder.indexOf('aorticDissection')
    var indexTamponnade = elementsOrder.indexOf('tamponnade')
    var indexHematoma = elementsOrder.indexOf('hematoma')
    var indexVascularAccessStenosis = elementsOrder.indexOf('vascularAccessStenosis')
    var indexAccessSiteClosureFailure = elementsOrder.indexOf('accessSiteClosureFailure')
    var indexComplicationSurgery = elementsOrder.indexOf('complicationSurgery')
    var indexConversionSavr = elementsOrder.indexOf('conversionSavr')
    var indexFistula = elementsOrder.indexOf('fistula')
    var indexInstability = elementsOrder.indexOf('instability')
    var indexInstabilityEcmo = elementsOrder.indexOf('instabilityEcmo')
    var indexInstabilityAssitance = elementsOrder.indexOf('instabilityAssitance')
    var indexNewAf = elementsOrder.indexOf('newAf')
    elements[indexMoreOneThv] = 0
    elements[indexFemoralStenting] = 0
    elements[indexAorticDissection] = 0
    elements[indexTamponnade] = 0
    elements[indexHematoma] = 0
    elements[indexVascularAccessStenosis] = 0
    elements[indexAccessSiteClosureFailure] = 0
    elements[indexComplicationSurgery] = 0
    elements[indexConversionSavr] = 0
    elements[indexFistula] = 0
    elements[indexInstability] = 0
    elements[indexInstabilityEcmo] = 0
    elements[indexInstabilityAssitance] = 0
    elements[indexNewAf] = 0
  }
  return elements
}

export var ex_data = [
  [
    'Stroke or TIA (30 days)',
    [
      'Edwards Sapien 3, No cerebral protection, 0.2%, Low risk',
      'Edwards Sapien 3, Cerebral protection, 0.1%, Low risk',
      'Evolut PRO, No cerebral protection, 0.2%, Low risk',
      'Evolut PRO, Cerebral protection, 0.1%, Low risk',
      'NAVITOR, No cerebral protection, 0.2%, Low risk',
      'NAVITOR, Cerebral protection, 0.1%, Low risk'
    ]
  ],
  [
    'Pacemaker (30 days)',
    [
      'Edwards Sapien 3, 7.3%, High risk',
      'Evolut PRO, 8.7%, High risk',
      'NAVITOR, 8.8%, High risk'
    ]
  ],
  [
    'Mortality (30 days)',
    [
      'Edwards Sapien 3, 0.2%, Low risk',
      'Evolut PRO, 0.2%, Low risk',
      'NAVITOR, 0.2%, Low risk'
    ]
  ],
  [
    'Cardiac technical success',
    [
      'Edwards Sapien 3, 0.1%, Low risk',
      'Evolut PRO, 0.1%, Low risk',
      'NAVITOR, 0.0%, Low risk'
    ]
  ],
  [
    'Vascular technical success',
    [
      'Edwards Sapien 3, 1.4%, Low risk',
      'Evolut PRO, 1.1%, Low risk',
      'NAVITOR, 1.0%, Low risk'
    ]
  ]
]

export const millisToMinutesAndSecondsFormated = (millis) =>  {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  var minFormat = ''
  if(minutes === 1){minFormat = ' minute '}
  if(minutes > 1){minFormat = ' minutes '}
  var secFormat = ''
  if(seconds === 1){secFormat = ' second'}
  if(seconds > 1){secFormat = ' seconds'}
  return (minutes > 0 ? minutes + minFormat : '') + seconds + secFormat
}

export var UnpackResult = (s) => {
  var s1 = s.split('; ')
  var s2 = s1.map(i => i.split(': '))
  var s3 = s2.map(i => [i[0], i[1].split('+')])
  return s3
}

const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (id, width, height) =>
  `https://source.unsplash.com/${id}/${width}x${height}`;

const unsplashPhotos = [
  { id: "8gVv6nxq6gY", width: 1080, height: 800 },
  { id: "Dhmn6ete6g8", width: 1080, height: 1620 },
  { id: "RkBTPqPEGDo", width: 1080, height: 720 },
  { id: "Yizrl9N_eDA", width: 1080, height: 721 },
  { id: "KG3TyFi0iTU", width: 1080, height: 1620 },
  { id: "Jztmx9yqjBw", width: 1080, height: 607 },
  { id: "-heLWtuAN3c", width: 1080, height: 608 },
  { id: "xOigCUcFdA8", width: 1080, height: 720 },
  { id: "1azAjl8FTnU", width: 1080, height: 1549 },
  { id: "ALrCdq-ui_Q", width: 1080, height: 720 },
  { id: "twukN12EN7c", width: 1080, height: 694 },
  { id: "9UjEyzA6pP4", width: 1080, height: 1620 },
  { id: "sEXGgun3ZiE", width: 1080, height: 720 },
  { id: "S-cdwrx-YuQ", width: 1080, height: 1440 },
  { id: "q-motCAvPBM", width: 1080, height: 1620 },
  { id: "Xn4L310ztMU", width: 1080, height: 810 },
  { id: "iMchCC-3_fE", width: 1080, height: 610 },
  { id: "X48pUOPKf7A", width: 1080, height: 160 },
  { id: "GbLS6YVXj0U", width: 1080, height: 810 },
  { id: "9CRd1J1rEOM", width: 1080, height: 720 },
  { id: "xKhtkhc9HbQ", width: 1080, height: 1440 },
];

const photos = unsplashPhotos.map((photo) => {
  const width = breakpoints[0];
  const height = (photo.height / photo.width) * width;

  return {
    src: unsplashLink(photo.id, width, height),
    width,
    height,
    srcSet: breakpoints.map((breakpoint) => {
      const height = Math.round((photo.height / photo.width) * breakpoint);
      return {
        src: unsplashLink(photo.id, breakpoint, height),
        width: breakpoint,
        height,
      };
    }),
  };
});

export const ImagesToPhotos = (list) => {
  if(list?.length === 0){return []}
  var photos = list.map((item, key) => {
    return ({src: 'https://www.medniscientai.com/static/media/'+item, 
    width: 400, 
    height: 400
  })})
  var res = []
  photos.map((photo) => {
    const width = breakpoints[0];
    const height = (photo.height / photo.width) * width;
    var t = {
      src: photo.src,
      width,
      height,
      srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((photo.height / photo.width) * breakpoint);
        return {
          src: photo.src,
          width: breakpoint,
          height,
        };
      }),
    };
    res.push(t)
  })
  return res
}