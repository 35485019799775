import React, { useState, useEffect, } from "react";

import { Col, Row, Container, } from 'react-bootstrap';
import { Button, } from '@mui/material';

import { FooterCustom } from  "./FooterCustom";
import ResponsiveAppBar from "./ResponsiveAppBar";

import pdfterms from '../../assets/docs/230616_cgu itavr en.pdf';


export const Terms = () => {

    return (
      <>
        <ResponsiveAppBar />
  
        <br />
        <br />

        <div style={{ marginTop:20, padding:20, }}>
          <p>Terms and conditions of use of the calculators</p>
          <p>The calculators provided by this website is providing information and services on the Internet as a benefit and service in furtherance of its educational mission.
          The user assumes all responsibility for, and holds ALVISS.AI harmless from any claims arising out of its use or misuse of, or inability to use the calculator,
          whether such claim is based on warranty, contract or damages (including negligence).
          Under no circumstances, shall ALVISS.AI be liable for any direct, indirect, incidental, special, consequential, exemplary or punitive damages, 
          or lost profits that result from the use or misuse of or inability to use the calculator.</p>
          <p>The owner of the website grants the user a limited, nonexclusive, nontransferable, 
          revocable access to utilize and access the service for your noncommercial, 
          personal use according to the terms and conditions provided here. 
          You may not modify, publish, transmit, participate in the transfer or sale of, reproduce, 
          create derivative works from, distribute, perform, display, incorporate into another website, 
          or in any other way exploit the service and/or any of the content, in whole or in part.</p>
          <p className="description text-center">We also offer an API access, please contact us for more information using the <a href='https://www.alviss.ai/cverc/#/bookdemo'>Contact</a> form.</p>
        </div>


        <Container className="pt-3">
          <div className="col px-0 pt-3">
            <Row className="align-items-center justify-content-center">
              <Col className="text-center" xs="12">

                <Button 
                  style={{ borderRadius:30, marginBottom:20, marginTop:10, 
                  textTransform:'none', maxWidth:150, }}
                  className="btn-neutral btn-icon ml-3"
                  color="info"
                  href={pdfterms}
                  download="pdfterms.pdf"
                  target="_blank"
                  variant="contained"
                  >
                  Terms
                </Button>

              </Col>
            </Row>

          </div>
        </Container>
  
        <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <br/>
      <br/>
      <br/>
      
        <FooterCustom />
  
      </>
    );
  };
  