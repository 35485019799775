import React, { useState, useEffect, } from "react";

import { Col, Row, Container, } from 'react-bootstrap';
import { Button, } from '@mui/material';

import { FooterCustom } from  "./FooterCustom";
import ResponsiveAppBar from "./ResponsiveAppBar";

import pdfpress230616 from '../../assets/docs/230616 itavr press.pdf';


export const Press = () => {

    return (
      <>
        <ResponsiveAppBar />
  
        <br />
        <br />
        <Container className="pt-3">
          <div className="col px-0 pt-3">
            <Row className="align-items-center justify-content-center">
              <Col className="text-center" lg="9">

                <Button 
                  style={{ borderRadius:30, marginBottom:20, marginTop:10, 
                  textTransform:'none', maxWidth:300, }}
                  className="btn-neutral btn-icon ml-3"
                  color="info"
                  href={pdfpress230616}
                  download="pdfpress230616.pdf"
                  target="_blank"
                  variant="contained"
                  >
                  2023 June - Press release
                </Button>

              </Col>
            </Row>

          </div>
        </Container>
  
  
        <FooterCustom />
  
      </>
    );
  };
  