import React, { useState, useEffect, useContext, } from "react";
import Modal from 'react-modal';
import { IconContext } from "react-icons/lib";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ICON_SIZE } from "../../styles/global";
import { modalStylesSmall, } from "../../components/helpers";

export const DescriptionModal = ({show, text, image}) => {
    const [showH, setShowH] = useState(show)
    // console.log('description modal ', show, text)
    const ToggleShow = () => {setShowH(!showH)}
    useEffect(()=>{
        setShowH(show)
    }, [show])

    const RenderImage = () => {
        if(image?.length>1){
            return (
            <img
                alt="Alviss AR pattern marker"
                className="img-center img-fluid shadow shadow-lg--hover"
                src={image}
                style={{ width:300 }}
            />
            )
        }
    }

    return (
        <div>
        <Modal
            isOpen={showH}
            onRequestClose={ToggleShow}
            style={modalStylesSmall}
            contentLabel="Metrics description"
            >
            <div style={{ flex:1, justifyContent:'space-between', }}>
                <div style={{ position:'absolute', top:-25, right:-20, zIndex:1000, 
                    backgroundColor:'transparent', }}>
                    <span style={{ padding:10, cursor:'pointer', alignItems:'center', justifyContent:'center', }} onClick={ToggleShow}>
                    <IconContext.Provider value={{ color: 'black', size:ICON_SIZE, }} >
                        <AiOutlineCloseCircle />
                    </IconContext.Provider>
                    </span>
                </div>
                <p>{text}</p>
                <RenderImage />

            </div>
        </Modal>
        </div>
        );
  }

