import React, { useState, useEffect, useContext, useCallback, } from "react";
import {  useMutation } from '@apollo/client';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";

import { Sidebar } from "../../components/Sidebar";
import Tracker from "../../components/tracker";

import { UPLOAD_CTTAVR_RESEARCH, } from '../../network/stroke';
import { globalStyles, } from "../../styles/global";
import { AuthContext } from "../../config/auth";
import { notifConfig, } from "../../components/helpers";

import { RoutesNames } from "../../routes";
import { FooterCustom } from "./FooterCustom";

import {useDropzone} from 'react-dropzone'
import { IconContext } from "react-icons";

import { AiOutlineCloudUpload } from "react-icons/ai";

import { NavbarInternal } from "./NavbarInternal";
import ct_chest from '../../assets/img/illustrations/chest_ct.png';
import { LinearProgressWithLabel } from "../../components/elements";

const notifyInfo = (message) => toast.info(message, {...notifConfig});
const notifyError = (message) => toast.error(message, {...notifConfig});


export const CttavrResearch = () => {
  const { user, } = useContext(AuthContext)

  let history = useNavigate();

  const [progress, setProgress] = useState(0);
  const INCREMENT = 1

  useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + INCREMENT));
      }, 1500); // 2.5 minutes
      return () => {
        clearInterval(timer);
      };
  }, []);

  useEffect(()=>{
    if(!user){
      history(RoutesNames.Signup.path);
    }
  }, [user])

  const [errors, setErrors] = useState()

  const [uploadCttavrFile, {data:datacv, loading:loadingcv, error:errorcv}] = useMutation(UPLOAD_CTTAVR_RESEARCH);

  const [message, setMessage] = useState()

  const [patientSelected, setPatientSelected] = useState('')

  const createFile = (bits, name, options) => {
    try {
        return new File(bits, name, options);
    } catch (e) {
        var myBlob = new Blob(bits, options || {});
        myBlob.lastModified = new Date();
        myBlob.name = name;
        return myBlob;
    }
  };

  const MyDropzone = () => {
    const onDrop = useCallback(files => {
      console.log('files', files)
      var fileNames = files.map(i => i.name).join(' ')
      // console.log('fileNames', fileNames)
      for(let i = 0; i < files.length; i++){
        const file = files[i]
        // console.log('file', file)
        if(!file?.name?.includes('.zip') && !file?.name?.includes('.7z')){
          const errMess = 'Your file does not appear to be compressed or zipped. Please compress or zip your folder (right click on the folder then Compress or Zip). Then upload your .zip or .7z file here!'
          setMessage(errMess)
          notifyError(errMess)
        }else{
          setMessage('')
          var file1 = createFile([file], '_'+file.name, {});
          uploadCttavrFile({ variables: { file:file1 } })
          // setPatientSelected(file?.name)
          setPatientSelected(fileNames)
          setProgress(0)
        }
      }
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  
    return (
      <div {...getRootProps()} 
        style={{ 
          height: 220, 
          cursor: 'pointer', 
          borderRadius: 10,
          backgroundColor: isDragActive ? '#f2f2f2' : 'white', 
          borderStyle: 'dashed',
          borderColor: '#1764c0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <input {...getInputProps()}/>
        {isDragActive ? (
          <p style={{ 
            fontSize: 18, 
            textAlign: 'center', 
            fontWeight: 'bold',
          }}>
            Drop the files here ...
          </p>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <IconContext.Provider value={{ color: 'rgb(54, 162, 235)', size: 130 }}>
              <AiOutlineCloudUpload />
            </IconContext.Provider>
            
            <p style={{ 
              color: 'black', 
              fontSize: 18, 
              fontWeight: 'bold',
              marginTop: 10,
              textAlign: 'center'
            }}>
              Drop the CT file (.zip)
            </p>
          </div>
        )}
      </div>
    )
  }

  useEffect(()=>{
    if(datacv?.uploadCttavrResearch){
      setProgress(100)
      var message = JSON.parse(datacv?.uploadCttavrResearch?.message)
      var mess = message?.message
      setMessage(mess ? mess : '')
      if(mess?.length>0){
        notifyInfo(mess)
      }
    }
  }, [datacv])

  // console.log('datacv', datacv)

  useEffect(()=>{
    if(errorcv){
      setProgress(100)
      console.log('errorcv', errorcv)
      if(errorcv?.message?.length>0){
        setErrors(errorcv?.message)
      }
    }
  }, [errorcv])

  useEffect(()=>{
    if(errors?.length>0){
      if(errors.includes('fetch')){
        notifyError('Error connecting to the server. Please try again!')
      } else if(errors.includes('token')) {
        notifyError('Error connecting to the server. Please check your Internet connection and try again!')
      } else if(errors.includes('processing')) {
        notifyError(errors)
      } else {
        notifyError('Sorry, an error occurred. Please try again!')
      }
      setErrors('')
      setPatientSelected('')
    }
  }, [errors])

  return (
    <div>
      {/* <Sidebar/>
      <ToastContainer/>
      <Tracker /> */}

      <main className="content p-0 m-0">
      <NavbarInternal />

      <div style={{ padding:10, }}>

        <div className="justify-content-center" style={{ padding:20, }}>
          <h2 className="text-center">Upload your CTs for research</h2>
        </div>

        <div className="justify-content-center" style={{ paddingBottom:20, }}>
          <h5 className="text-center" style={{  color:'#5f6369', }}>Send one or more CTs at a time</h5>
        </div>

      <br/>

      {!loadingcv ? 
      <div className="justify-content-center" 
      style={{ borderRadius:10, }}
      >
        <MyDropzone />
      </div>
      : null}

      {loadingcv ? (
        <div style={{ marginTop: 40, padding: 10 }}>
          <br/>
          <LinearProgressWithLabel value={progress} />
          <div className="flex flex-col items-center justify-center mt-4">
            <span className="text-center"> 
              Uploading {patientSelected} ...
            </span>
          </div>
          <br/>
        </div>
      ) : null}

      {message?.length > 0 ? (
        <div className="flex justify-center items-center my-8">
          <span className="text-xl text-center p-2">
            {message}
          </span>
        </div>
      ) : null}


      <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <br/>
      <br/>
      <br/>

      <div style={{ backgroundImage: `url(${ct_chest})`, padding:40, height:150, }}></div>

      {/* <br/>
      <br/>
      <br/>
      <br/> */}

    </div>

    <FooterCustom />

    </main>

    </div>
  );
};
