import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';

import './styles/tailwind.css';

import "react-datetime/css/react-datetime.css";

import { client } from './config/client';
import { ApolloProvider} from '@apollo/client';
import { GlobalProvider } from './config/globalstates';
import { AuthProvider } from './config/auth';

import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';

import { HomePageRouting } from './pages/HomePage';
import ScrollToTop from "./components/ScrollToTop";
import './index.css';
import { GOOGLE_AUTH_CLIENT_ID } from './config/config';

// Set the app element to the root div
Modal.setAppElement('#root');  // or '#app' depending on your root element's id

const root = createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <ScrollToTop />
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={client}>
        <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
          <AuthProvider>
            <GlobalProvider>
                <HomePageRouting />
            </GlobalProvider>
          </AuthProvider>
        </GoogleOAuthProvider>
      </ApolloProvider>
    </I18nextProvider>
  </HashRouter>
);
