import React from 'react';
import { Route, Routes, } from "react-router-dom";
import { RoutesNames } from "../routes";

// pages
import {Presentation} from "./Presentation";
import { Cttavr } from './dashboard/Cttavr';

import {Signin} from "./dashboard/Signin";
import { Signup } from './dashboard/Signup';
import {Bookdemo} from './dashboard/Bookdemo';

import NotFoundPage from "./pages/NotFound";
import { Terms } from './dashboard/Terms';
import { Privacy } from './dashboard/Privacy';
import { Press } from './dashboard/Press';
import { Team } from './dashboard/Team';
import { CttavrResearch } from './dashboard/CttavrResearch';
import { AITAVR } from './PresentationTAVR';
import { AIPE } from './PresentationPE';
import { Ctpe } from './dashboard/Ctpe';
import { CtpeResearch } from './dashboard/CtpeResearch';

export const HomePageRouting = () => (
    <Routes>
    <Route path={RoutesNames.Presentation.path} element={<Presentation/>} />
    <Route path={RoutesNames.AITAVR.path} element={<AITAVR/>} />
    <Route path={RoutesNames.AIPE.path} element={<AIPE/>} />
    <Route path={RoutesNames.Signin.path} element={<Signin/>} />
    <Route path={RoutesNames.Signup.path} element={<Signup/>} />
    <Route path={RoutesNames.Bookdemo.path} element={<Bookdemo/>} />
    <Route path={RoutesNames.Press.path} element={<Press/>} />
    <Route path={RoutesNames.Terms.path} element={<Terms/>} />
    <Route path={RoutesNames.Privacy.path} element={<Privacy/>} />
    <Route path={RoutesNames.Team.path} element={<Team/>} />

    <Route path={RoutesNames.Cttavr.path} element={<Cttavr/>} />
    <Route path={RoutesNames.CttavrResearch.path} element={<CttavrResearch/>} />
    <Route path={RoutesNames.Ctpe.path} element={<Ctpe/>} />
    <Route path={RoutesNames.CtpeResearch.path} element={<CtpeResearch/>} />

    <Route path='*' element={<NotFoundPage />} />

  </Routes>
);
