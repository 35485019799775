import { Presentation } from "./pages/Presentation";
import { Bookdemo } from "./pages/dashboard/Bookdemo";
import { AITAVR } from "./pages/PresentationTAVR";
import { AIPE } from "./pages/PresentationPE";
import NotFound from "./pages/pages/NotFound";

export const routes = [
  {
    path: "/",
    element: <Presentation />,
  },
  {
    path: "/bookdemo",
    element: <Bookdemo />,
  },
  {
    path: "/aitavr",
    element: <AITAVR />,
  },
  {
    path: "/aipe",
    element: <AIPE />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export const RoutesNames = {
    Presentation: { path: "/" },
    Bookdemo: { path: "/bookdemo" },
    Signin: { path: "/signin" },
    Signup: { path: "/signup" },
    
    AITAVR: { path: "/aitavr" },
    AIPE: { path: "/aipe" },
    Cttavr: { path: "/cttavr" },
    CttavrResearch: { path: "/research" },
    Ctpe: { path: "/ctpe" },
    CtpeResearch: { path: "/researchpe" },
    Press: { path: "/press" },
    Terms: { path: "/terms" },
    Privacy: { path: "/privacy" },
    Team: { path: "/team" },

    // ForgotPassword: { path: "/forgot-password" },
    // ResetPassword: { path: "/reset-password" },
    Lock: { path: "/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
};