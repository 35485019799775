import React, { useState } from "react";
import Modal from 'react-modal';
import { IconContext } from "react-icons";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsShareFill } from 'react-icons/bs';
import moment from "moment-timezone";
import { 
  EmailShareButton, FacebookShareButton, PinterestShareButton, RedditShareButton, 
  TelegramShareButton, TumblrShareButton, TwitterShareButton, WhatsappShareButton, 
  WeiboShareButton, LinkedinShareButton, 
  EmailIcon, FacebookIcon, PinterestIcon, RedditIcon, 
  TelegramIcon, TumblrIcon, TwitterIcon, WhatsappIcon, WeiboIcon, LinkedinIcon, 
} from "react-share";

import { modalStyles } from "../../components/helpers";
import { ICON_SIZE } from "../../styles/global";

export const FooterCustom = () => {
  const currentYear = moment().get("year");
  const [openShareModal, setOpenShareModal] = useState(false);
  const LINK_CV = "https://www.medniscientai.com/";
  const QUOTE_CV = "Use #ai powered #predictivemedicine to support your decisions! With Medniscient AI #medtech #alviss #digitalhealth";
  const SHARE_ICON_SIZE = 50;
  const styleShareButton = { margin: 10 };
  const EMAIL_SUBJECT = "Discover Medniscient AI";

  return (
    <footer className="py-5 px-2">
      <Modal
        isOpen={openShareModal}
        onRequestClose={() => setOpenShareModal(false)}
        style={modalStyles}
        contentLabel="Metrics"
      >
        <div className="flex justify-center items-center">
          <div className="absolute -top-6 -right-5 z-50 bg-transparent">
            <span 
              className="p-2.5 cursor-pointer" 
              onClick={() => setOpenShareModal(false)}
            >
              <IconContext.Provider value={{ color: 'black', size: ICON_SIZE }}>
                <AiOutlineCloseCircle />
              </IconContext.Provider>
            </span>
          </div>
          <div>Share to: </div>
          <div className="whitespace-pre-wrap break-words">
            <EmailShareButton url={LINK_CV} subject={EMAIL_SUBJECT} body={QUOTE_CV} style={styleShareButton}>
              <EmailIcon size={SHARE_ICON_SIZE} round={true} />
            </EmailShareButton>
            <LinkedinShareButton url={LINK_CV} source={LINK_CV} title={"Interesting CV"} summary={QUOTE_CV} style={styleShareButton}>
              <LinkedinIcon size={SHARE_ICON_SIZE} round={true} />
            </LinkedinShareButton>
            <TwitterShareButton  url={LINK_CV} title={QUOTE_CV} hashtags={["alvisscv"]} style={styleShareButton}>
              <TwitterIcon size={SHARE_ICON_SIZE} round={true} />
            </TwitterShareButton>
            <FacebookShareButton url={LINK_CV} quote={QUOTE_CV} hashtag="alvisscv" style={styleShareButton}>
              <FacebookIcon size={SHARE_ICON_SIZE} round={true} />
            </FacebookShareButton>
            <PinterestShareButton url={LINK_CV} media={'https://www.alviss.ai/static/images/alviss_white.png'} description={QUOTE_CV} style={styleShareButton}>
              <PinterestIcon size={SHARE_ICON_SIZE} round={true} />
            </PinterestShareButton>
            <RedditShareButton url={LINK_CV} title={QUOTE_CV} style={styleShareButton}>
              <RedditIcon size={SHARE_ICON_SIZE} round={true} />
            </RedditShareButton>
            <TumblrShareButton url={LINK_CV} title={"Interesting CV"} caption={QUOTE_CV} style={styleShareButton}>
              <TumblrIcon size={SHARE_ICON_SIZE} round={true} />
            </TumblrShareButton>
            <TelegramShareButton url={LINK_CV} title={QUOTE_CV} style={styleShareButton}>
              <TelegramIcon size={SHARE_ICON_SIZE} round={true} />
            </TelegramShareButton>
            <WhatsappShareButton url={LINK_CV} title={QUOTE_CV} style={styleShareButton}>
              <WhatsappIcon size={SHARE_ICON_SIZE} round={true} />
            </WhatsappShareButton>
            <WeiboShareButton url={LINK_CV} title={QUOTE_CV} image={"https://www.alviss.ai/static/images/alviss_white.png"} style={styleShareButton}>
              <WeiboIcon size={SHARE_ICON_SIZE} round={true} />
            </WeiboShareButton>
          </div>
        </div>
      </Modal>

      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="mb-4 lg:mb-0">
            <p className="mb-0 text-center lg:text-left">
              Copyright © 2021-{`${currentYear} `}
              <a 
                href="https://www.medniscientai.com" 
                target="_blank" 
                rel="noreferrer"
                className="text-blue-600 hover:text-blue-800 no-underline"
              >
                Medniscient AI
              </a>
            </p>
          </div>

          <div>
            <ul className="flex flex-wrap justify-center lg:justify-end items-center space-x-4 mb-0">
              <li>
                <a 
                  href="https://www.medniscientai.com" 
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-700 hover:text-blue-600"
                >
                  Commercial page
                </a>
              </li>
              <li>
                <a 
                  href="https://www.medniscientai.com/#/aipe" 
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-700 hover:text-blue-600"
                >
                  AI PE
                </a>
              </li>
              <li>
                <a 
                  href="https://www.medniscientai.com/#/bookdemo" 
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-700 hover:text-blue-600"
                >
                  Contact us
                </a>
              </li>
              <li>
                <a 
                  href="https://www.medniscientai.com/#/press" 
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-700 hover:text-blue-600"
                >
                  Press
                </a>
              </li>
              <li>
                <a 
                  href="https://www.medniscientai.com/#/terms" 
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-700 hover:text-blue-600"
                >
                  Terms
                </a>
              </li>
              <li>
                <a 
                  href="https://www.medniscientai.com/#/team" 
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-700 hover:text-blue-600"
                >
                  Team
                </a>
              </li>
              <li>
                <a 
                  href="https://www.medniscientai.com/#/privacy" 
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-700 hover:text-blue-600"
                >
                  Privacy
                </a>
              </li>
              {/* <li>
                <span 
                  onClick={() => setOpenShareModal(true)} 
                  className="cursor-pointer mr-5"
                >
                  <IconContext.Provider value={{ color: 'black', size: 30 }}>
                    <BsShareFill/>
                  </IconContext.Provider>
                </span>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

