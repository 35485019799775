import { useNavigate } from "react-router-dom";

import { FaCheckCircle, } from 'react-icons/fa';

import { Container, Grid, Box } from '@mui/material';
import { Button } from '@mui/material';

import { RoutesNames } from "../routes";
import { FooterCustom } from "./dashboard/FooterCustom";
import ResponsiveAppBarPE from "./dashboard/ResponsiveAppBarPE";

import happy from '../assets/img/brand/happy_old_man2.jpg';


export const AIPE = () => {
  let history = useNavigate();

  return (
    <>
      <ResponsiveAppBarPE/>

      <section className="py-12">
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} lg={5}>
              <div className="flex flex-col items-center text-center space-y-6">
                <h2 className="text-3xl font-bold text-black">
                  AI PE to diagnose pulmonary embolism.
                </h2>

                <h4 className="text-xl text-black">
                  AI PE is revolutionizing pulmonary embolism and chest pain care.
                </h4>

                <p className="text-black">
                  AI PE reads CT scan images to diagnose and analyze the severity of pulmonary embolism.
                </p>

                <Button 
                  onClick={() => history(RoutesNames.Bookdemo.path)}
                  variant="contained"
                  className="rounded-full normal-case"
                >
                  Book a free demo
                </Button>
              </div>
            </Grid>

            <Grid item xs={12} lg={6} sx={{ marginTop: 5, }}>
              <div className="flex justify-center">
                <img
                  src={happy}
                  alt="AI PE"
                  className="max-w-[500px] w-full rounded-tl-[40px] shadow-lg"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="bg-[#1764c0] py-12">
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} lg={12}>
              <h4 className="text-center text-white mb-8">
                Our cloud native technology can be integrated in your electronic medical records software.
              </h4>
              
              <div className="space-y-4">
                <p className="flex items-center gap-2 text-white">
                  <FaCheckCircle className="text-green-500" />
                  But AI PE doesn't need EMR integration. It is an end to end AI that reads CT scan images automatically.
                </p>
                <p className="flex items-center gap-2 text-white">
                  <FaCheckCircle className="text-green-500" />
                  AI PE has an API-ready system.
                </p>
                <p className="flex items-center gap-2 text-white">
                  <FaCheckCircle className="text-green-500" />
                  AI PE has a ready-to-use Web application.
                </p>
                <p className="flex items-center gap-2 text-white">
                  <FaCheckCircle className="text-green-500" />
                  AI PE can be easily integrated into your PACS or CT processing software.
                </p>
                <p className="text-white">AI PE is the first fully automated pulmonary embolism diagnosis and decision support algorithm.</p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <FooterCustom />

    </>
  );
};
