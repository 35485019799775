import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useMutation, } from '@apollo/client';
import { useNavigate, useSearchParams } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';
import { MdOutlineEmail, MdVerifiedUser } from 'react-icons/md';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { IconContext } from "react-icons";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Sentry } from "react-activity";
import "react-activity/dist/Sentry.css";

import { 
  Container, 
  Grid, 
  Paper, 
  TextField,
  InputAdornment,
  Typography,
  Stack
} from '@mui/material';
import { FaAngleLeft } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { RoutesNames } from "../../routes";

import { LOGIN_USER, LOGIN_USER_EMAIL } from '../../network/user';
import { AuthContext } from '../../config/auth';
import { notifConfig, } from '../../components/helpers';
import { ICON_SIZE } from '../../styles/global';
// import { GlobalContext } from '../../config/globalstates';

const notifyInfo = (message) => toast.info(message, {...notifConfig});
const notifyError = (message) => toast.error(message, {...notifConfig});

const LoginFormSchema = yup.object ({
  username: yup.string ().required ('Username or email is required'),
  password: yup.string (),
});

export const Signin = () => {
  const [searchParams] = useSearchParams();
  const next = searchParams.get("next")
  // console.log('signin next', next)

  let history = useNavigate();
  const context = useContext(AuthContext)

  const [ errors, setErrors ] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const TogglePasswordVisible = () => {setPasswordVisible(!passwordVisible)}
  const [withEmail, setWithEmail] = useState(false)
  const ToggleWithEmail = () => {setWithEmail(!withEmail)}
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [socialLogin, setSocialLogin] = useState([]);

  const [loginUser, {data, loading, error }] = useMutation (LOGIN_USER, {
    update(_, {data: { tokenAuth: userData}}){
      context.login(userData)
    },
    onError(err){
      setErrors(err);
    },
  });

  const [loginUserEmail, {data:datae, loading:loadinge, error:errore }] = useMutation (LOGIN_USER_EMAIL, {
    update(_, {data: { tokenAuth: userData}}){
      context.login(userData)
    },
    onError(err){
      setErrors(err);
    },
  });

  useEffect(() => {
    let mounted = true;
    if(data?.tokenAuth?.errors?.nonFieldErrors){
      var errorH = data.tokenAuth.errors.nonFieldErrors[0].message.replace('.', '')
      var errorH1 = errorH
      setErrors(errorH1)
    }
    if(datae?.tokenAuth?.errors?.nonFieldErrors){
      var errorH = datae.tokenAuth.errors.nonFieldErrors[0].message.replace('.', '')
      var errorH1 = errorH
      setErrors(errorH1)
    }
    return () => {mounted = false}
  }, [data, datae])

  useEffect(() => {
    if(errors){
      notifyError(errors)
      setErrors()
    }
    return () => {}
  }, [errors])

  useEffect(() => {
    let mounted = true;
    if(context.user){
      if(next){
        history(next, { state: { source: 'sign in' } } )
      }else {
        history(RoutesNames.Cttavr.path, { state: { source: 'sign in' } } )
      }
    }
    return () => {mounted = false}
  }, [context.user])

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginFormSchema,
    onSubmit: (values, actions) => {
      // console.log("form ", values)
      setUsername(values.username)
      setPassword(values.password)
      if(withEmail){
        loginUserEmail({variables: {
                email:values.username, 
                password:values.password}}
                )
      }else{
        loginUser({variables: {
                username:values.username, 
                password:values.password,
              }}
                )
      }
    },
  });

  // console.log('sign in page 1', data)
  return (
    <main>
      <Container sx={{ my: 5 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <Paper elevation={3} sx={{ p: 4, width: '100%', maxWidth: 500 }}>
              <Stack spacing={3}>
                <Button 
                  variant="text" 
                  onClick={() => history(RoutesNames.Presentation.path)} 
                  align="center"
                >
                  <FaAngleLeft className="me-2" /> Back to Home
                </Button>

                <Typography variant="h5" align="center">Medical Intelligence</Typography>
                <Typography variant="h5" align="center">Log In</Typography>

                <form onSubmit={formik.handleSubmit}>
                  <Stack spacing={3}>
                    <TextField
                      fullWidth
                      required
                      type={withEmail ? "email" : "text"}
                      id="username"
                      placeholder={withEmail ? "example@company.com" : "Username"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span style={{ cursor: 'pointer' }} onClick={ToggleWithEmail}>
                              <IconContext.Provider value={{ color:'black', size:ICON_SIZE}}>
                                {withEmail ? <MdOutlineEmail/> : <MdVerifiedUser/>}
                              </IconContext.Provider>
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      {...formik.getFieldProps('username')}
                    />

                    <TextField
                      fullWidth
                      required
                      type={passwordVisible ? 'text' : 'password'}
                      id="password"
                      placeholder="Password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span style={{ cursor: 'pointer' }} onClick={TogglePasswordVisible}>
                              <IconContext.Provider value={{ color:'black', size:ICON_SIZE}}>
                                {passwordVisible ? <AiFillEyeInvisible/> : <AiFillEye/>}
                              </IconContext.Provider>
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      {...formik.getFieldProps('password')}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button 
                        disabled={loading || loadinge}
                        variant="contained"
                        type="submit"
                      >
                        {loading || loadinge ? <Sentry /> : "Log In"}
                      </Button>
                    </Box>
                  </Stack>
                </form>

                <Typography align="center">
                  <Link 
                    to="https://www.medniscientai.com/password-reset" 
                    target="_blank"
                    className="fw-bold"
                  >
                    Forgot password?
                  </Link>
                </Typography>

                <Typography align="center">
                  Don't have an account yet?
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    color="primary" 
                    variant="contained"
                    onClick={() => {
                      if(next){
                        history(RoutesNames.Signup.path + '?next=' + next);
                      } else {
                        history(RoutesNames.Signup.path);
                      }
                    }}
                  > 
                    Sign Up
                  </Button>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};
