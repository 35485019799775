import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

import { useFormik, } from 'formik';
import * as yup from 'yup';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { IconContext } from "react-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Sentry } from "react-activity";
import "react-activity/dist/Sentry.css";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

import { FaAngleLeft, FaEnvelope, FaSignInAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { 
  Container, 
  Grid, 
  Paper, 
  TextField,
  InputAdornment,
  Typography,
  Stack
} from '@mui/material';

import { RoutesNames } from "../../routes";

import { REGISTER_USER, LOGIN_USER } from '../../network/user';
import { AuthContext } from '../../config/auth';
import { notifConfig } from '../../components/helpers';
import { ICON_SIZE } from '../../styles/global';
import { GlobalContext } from '../../config/globalstates';
import { useMutation } from '@apollo/client';

const notifyError = (message) => toast.error(message, {...notifConfig});

const SignUpFormSchema = yup.object ({
  username: yup.string ().required ('Username is required'),
  email: yup.string ().email ().required ('Email is required'),
  password1: yup.string ().required ('Password is required'),
  password2: yup
    .string ()
    .oneOf ([yup.ref ('password1'), null], 'Passwords must match')
    .required ('Password confirmation is required'),
});

export const Signup = () => {
  const [searchParams] = useSearchParams();
  const next = searchParams.get("next")

  let history = useNavigate();
  const context = useContext(AuthContext)
  const { lastVisited, changeLastVisited } = useContext(GlobalContext)

  const [ errors, setErrors ] = useState();  
  const [passwordVisible, setPasswordVisible] = useState(false);
  const TogglePasswordVisible = () => {setPasswordVisible(!passwordVisible)}
  const [userLogin, setUserLogin] = useState();

  useEffect(() => {
    let mounted = true;
    if(context.user){
      // history(RoutesNames.Cttavr.path, { state: { source: 'sign up' } });
      if(next){
        history(next, { state: { source: 'sign up' } } )
      }else {
        history(RoutesNames.Cttavr.path, { state: { source: 'sign up' } } )
      }
    }
    return () => {mounted = false}
  }, [context.user])

  const [addUser, { data, loading, error }] = useMutation (REGISTER_USER);
  const [loginUser, {data:datalogin, loading:loadinglogin, error:errorlogin }] = useMutation (LOGIN_USER, {
    update(_, {data: { tokenAuth: userData}}){
      context.login(userData)
    },
  });

  const submitNewUser = (values) => {
    addUser({variables: {username:values.username, email:values.email,
    password1:values.password1, password2:values.password2}})
    setUserLogin({username:values.username, password:values.password1})
  }

  useEffect(() => {
    let mounted = true;
    if(data?.register?.success && userLogin){
      loginUser({variables: {username:userLogin.username, password:userLogin.password}})
      if(lastVisited==='/institution'){changeLastVisited(RoutesNames.Account.path)}
    }
    if(data?.register?.errors){
      var e = data.register.errors
      if(e.username){var errorH = e.username[0].message+': '+userLogin.username}
      if(e.email){var errorH = e.email[0].message}
      if(e.password2){var errorH = e.password2[0].message}
      if(errorH){setErrors(errorH)}
    }
    if(datalogin?.tokenAuth?.errors?.nonFieldErrors){
      var errorH = datalogin.tokenAuth.errors.nonFieldErrors[0].message
      setErrors(errorH)
    }
    return () => {mounted = false}
  }, [data])

  useEffect(() => {
    if(errors){
      notifyError(errors)
      setErrors()
    }
    return () => {}
  }, [errors])

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password1: '',
      password2: '',
    },
    validationSchema: SignUpFormSchema,
    onSubmit: (values, actions) => {
      // console.log("form ", values)
      submitNewUser(values)
    },
  });

  // console.log('signup', formik.errors)

  return (
    <main>
      <Container sx={{ my: 5 }}>

        <Grid container justifyContent="center">
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <Paper elevation={3} sx={{ p: 4, width: '100%', maxWidth: 500 }}>
              <Stack spacing={3}>
                <Button 
                  variant="text" 
                  onClick={() => history(RoutesNames.Presentation.path)} 
                  align="center"
                >
                  <FaAngleLeft className="me-2" /> Back to Home
                </Button>

                <h2 className="text-center font-bold">Create an account</h2>

                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    width: '100%'
                  }}
                >
                  <GoogleLogin
                    onSuccess={credentialResponse => {
                      console.log(credentialResponse);
                      var decoded = jwt_decode(credentialResponse?.credential);
                      var pass = Math.random().toString(36).slice(-8);
                      var vals = {
                        username: decoded?.given_name + decoded?.family_name,
                        email: decoded?.email, password1: pass, password2: pass,
                      }
                      submitNewUser(vals)
                    }}
                    onError={() => {
                      console.log('Login Failed');
                      setErrors('Login Failed! Please try again using the form below!')
                    }}
                    useOneTap
                  />
                </Box>

                <Typography variant="h6" align="center">Or</Typography>

                <form onSubmit={formik.handleSubmit}>
                  <Stack spacing={3}>
                    <TextField
                      fullWidth
                      required
                      id="username"
                      placeholder="Username"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FaSignInAlt />
                          </InputAdornment>
                        ),
                      }}
                      {...formik.getFieldProps('username')}
                    />

                    <TextField
                      fullWidth
                      required
                      type="email"
                      id="email"
                      placeholder="Email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FaEnvelope />
                          </InputAdornment>
                        ),
                      }}
                      {...formik.getFieldProps('email')}
                    />

                    <TextField
                      fullWidth
                      required
                      type={passwordVisible ? 'text' : 'password'}
                      id="password1"
                      placeholder="Password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span style={{ cursor: 'pointer' }} onClick={TogglePasswordVisible}>
                              <IconContext.Provider value={{ color:'black', size:ICON_SIZE}}>
                                {passwordVisible ? <AiFillEyeInvisible/> : <AiFillEye/>}
                              </IconContext.Provider>
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      {...formik.getFieldProps('password1')}
                      error={formik.touched.password1 && Boolean(formik.errors.password1)}
                      helperText={formik.touched.password1 && formik.errors.password1}
                    />

                    <TextField
                      fullWidth
                      required
                      type={passwordVisible ? 'text' : 'password'}
                      id="password2"
                      placeholder="Confirm password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span style={{ cursor: 'pointer' }} onClick={TogglePasswordVisible}>
                              <IconContext.Provider value={{ color:'black', size:ICON_SIZE}}>
                                {passwordVisible ? <AiFillEyeInvisible/> : <AiFillEye/>}
                              </IconContext.Provider>
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      {...formik.getFieldProps('password2')}
                      error={formik.touched.password2 && Boolean(formik.errors.password2)}
                      helperText={formik.touched.password2 && formik.errors.password2}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button 
                        disabled={loading || loadinglogin}
                        variant="contained"
                        type="submit"
                      >
                        {loading || loadinglogin ? <Sentry /> : "Sign Up"}
                      </Button>
                    </Box>
                  </Stack>
                </form>

                <Typography align="center">
                  Already have an account?
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    color="primary" 
                    variant="contained"
                    type="submit"
                    onClick={() => {
                      if(next){
                        history(RoutesNames.Signin.path + '?next=' + next);
                      } else {
                        history(RoutesNames.Signin.path);
                      }
                    }}
                  > 
                    Log In
                  </Button>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};
