import React from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { RoutesNames } from "../../routes";

// Make sure to export as default
const NotFound = () => {
  return (
    <main className="min-h-screen flex items-center justify-center">
      <div className="container mx-auto px-4">
        <div className="text-center">
          <Link to={RoutesNames.Presentation.path}>
            <img 
              src="https://www.alviss.ai/media/images/404.svg" 
              alt="404"
              className="mx-auto w-3/4 max-w-lg"
            />
          </Link>
          
          <h1 className="text-primary mt-5 text-3xl">
            Page not <span className="font-bold">found</span>
          </h1>
          
          <p className="text-lg my-4 text-gray-600">
            Oops! Looks like you followed a bad link. If you think this is a
            problem with us, please tell us.
          </p>
          
          <Link 
            to={RoutesNames.Presentation.path}
            className="inline-flex items-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors"
          >
            <FontAwesomeIcon 
              icon={faChevronLeft} 
              className="mr-2 animate-bounce-left"
            />
            Go back home
          </Link>
        </div>
      </div>
    </main>
  );
};

// Add both default and named exports
export default NotFound;
export { NotFound };
