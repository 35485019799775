import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { IconContext } from "react-icons";
import CircularProgress from '@mui/material/CircularProgress';

import { Button, TextField } from '@mui/material';
import { RoutesNames } from "../../routes";
import { SEND_MESSAGE_ADMIN } from "../../network/profile";
import { notifConfig } from "../../components/helpers";
import receptionist from "../../assets/img/icons/receptionist.jpg";

const notifyInfo = (message) => toast.info(message, {...notifConfig});
const notifyError = (message) => toast.error(message, {...notifConfig});

export const Bookdemo = () => {
    let history = useNavigate();
    const initialValues = {
      name: '',
      email: '',
      phone: '',
      message: '',
    }
    const [values, setValues] = useState(initialValues);
    const [canSend, setCanSend] = useState(false);
    const [sendMessage, { data, loading, error }] = useMutation(SEND_MESSAGE_ADMIN);

    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    };

    const sendMessageGo = () => {
      if(canSend) {
        var mess = [values.name, values.email, values.phone, 'Book a demo: '+values.message].join(' ')
        sendMessage({variables:{message:mess}})
      }
    }

    useEffect(() => {
      if(data) {
        if(data?.sendMessageAdmin?.status) {
          notifyInfo("Your request was sent to our staff! We will be in touch shortly!")
          setValues(initialValues)
          setCanSend(false)
        } else {
          notifyError("Sorry we could not process your request! Please check that you filled all the necessary information!")
        }
      }
    }, [data])

    useEffect(() => {
      if(values?.name?.length>0 && values?.email?.length>0 && values?.email?.includes('@') && values?.phone?.length>0 && values?.message?.length>0) {
        setCanSend(true)
      }
    }, [values])

    return (
        <div className="flex-1 min-h-screen bg-gray-50">
            <ToastContainer/>
            <section className="flex items-center my-5 mt-lg-6 mb-lg-5">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-4">
                        <span 
                            className="inline-flex items-center px-3 py-2 rounded hover:bg-gray-100 cursor-pointer"
                            onClick={() => history(RoutesNames.Presentation.path)}
                        >
                            <IconContext.Provider value={{ size: 20 }}>
                                <IoArrowBack className="mr-2" />
                            </IconContext.Provider>
                            Back to homepage
                        </span>
                    </div>

                    <div className="flex justify-center">
                        <div className="w-full max-w-lg bg-white shadow-md rounded-lg border border-gray-200 p-8">
                            <div className="text-center mb-6">
                                <h3 className="text-2xl font-bold mb-4">Book a demo</h3>
                                
                                <div className="flex justify-center">
                                    <img 
                                        className="w-24 h-24 rounded-full shadow-lg object-cover"
                                        src={receptionist} 
                                        alt="Receptionist"
                                    />
                                </div>
                            </div>

                            <div className="space-y-6">
                                <TextField
                                    fullWidth
                                    label="Your name"
                                    name="name"
                                    onChange={handleChange}
                                    required
                                    value={values.name}
                                    variant="outlined"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e0e0e0',
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#1976d2',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#1976d2',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#666',
                                            '&.Mui-focused': {
                                                color: '#1976d2',
                                            },
                                        },
                                        backgroundColor: 'white',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                                    }}
                                />
                                
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    onChange={handleChange}
                                    required
                                    value={values.email}
                                    variant="outlined"
                                    type="email"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e0e0e0',
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#1976d2',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#1976d2',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#666',
                                            '&.Mui-focused': {
                                                color: '#1976d2',
                                            },
                                        },
                                        backgroundColor: 'white',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                                    }}
                                />
                                
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    name="phone"
                                    onChange={handleChange}
                                    required
                                    value={values.phone}
                                    variant="outlined"
                                    type="tel"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e0e0e0',
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#1976d2',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#1976d2',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#666',
                                            '&.Mui-focused': {
                                                color: '#1976d2',
                                            },
                                        },
                                        backgroundColor: 'white',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                                    }}
                                />
                                
                                <TextField
                                    fullWidth
                                    label="Message"
                                    name="message"
                                    onChange={handleChange}
                                    required
                                    value={values.message}
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#e0e0e0',
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#1976d2',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#1976d2',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#666',
                                            '&.Mui-focused': {
                                                color: '#1976d2',
                                            },
                                        },
                                        backgroundColor: 'white',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                                    }}
                                />
                            </div>

                            {/* Submit Button */}
                            <div className="mt-6 flex justify-center">
                                <Button
                                    disabled={loading || !canSend}
                                    color="primary"
                                    variant="contained"
                                    onClick={sendMessageGo}
                                    className="w-full sm:w-auto"
                                >
                                    {loading ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) : (
                                        "Send"
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

