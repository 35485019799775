
import { Col, Row, Container, } from 'react-bootstrap';
import { Button, } from '@mui/material';

import { FooterCustom } from  "./FooterCustom";
import ResponsiveAppBar from "./ResponsiveAppBar";

import pdfprivacy from '../../assets/docs/230616 privacy itavr.pdf';


export const Privacy = () => {

    return (
      <>
        <ResponsiveAppBar />
  
        <br />
        <br />
        <Container className="pt-3">
          <div className="col px-0 pt-3">
            <Row className="align-items-center justify-content-center">
              <Col className="text-center" xs="12">

                <Button 
                  style={{ borderRadius:30, marginBottom:20, marginTop:10, 
                  textTransform:'none', maxWidth:150, }}
                  className="btn-neutral btn-icon ml-3"
                  color="info"
                  href={pdfprivacy}
                  download="pdfprivacy.pdf"
                  target="_blank"
                  variant="contained"
                  >
                  Privacy policy
                </Button>

              </Col>
            </Row>

          </div>
        </Container>
  
      <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <br/>
      <br/>
      <br/>

        <FooterCustom />
  
      </>
    );
  };
  