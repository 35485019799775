import { useNavigate } from "react-router-dom";

import { Container, Grid, Card, Box } from '@mui/material';
import { TwitterFollowButton, } from 'react-twitter-embed';
import { LinkedinFollowCompany } from 'react-social-plugins';
import {  Button } from '@mui/material';

import { RoutesNames } from "../routes";
import { FooterCustom } from "./dashboard/FooterCustom";
import ResponsiveAppBar from "./dashboard/ResponsiveAppBar";
import happy from '../assets/img/brand/happy_old_man1.jpg';
import happy1 from '../assets/img/brand/happy_old_woman1.jpg';
import { FaExternalLinkAlt, FaCheckCircle, FaFolder } from 'react-icons/fa';

export const Presentation = () => {
  let history = useNavigate();

  return (
    <>
      <ResponsiveAppBar/>

      <section className="py-12">
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} lg={5}>
              <div className="text-center">
                <div className="space-y-6">
                  <h2 className="text-4xl font-bold text-black">
                    Empowering Tomorrow's Health, Today.
                  </h2>
                  
                  <h4 className="text-xl text-black">
                    Medniscient AI is a digital healthcare company revolutionizing the way clinicians approach interventional and surgical treatment.
                  </h4>
                  
                  <p className="text-black">
                    Our clinically-proven AI digital healthcare platform works with cardiovascular computed tomography angiography to help clinicians identify prognostic patterns, so they can provide personalized, life-saving treatment plans for patients requiring an intervention.
                  </p>
                  
                  <Button 
                    onClick={() => history(RoutesNames.Bookdemo.path)}
                    variant="contained"
                    className="rounded-full normal-case"
                  >
                    Book a free demo
                  </Button>

                </div>
              </div>
            </Grid>


            <br/>

            <Grid item xs={12} lg={6} sx={{ marginTop: 5, }}>
              <div className="flex justify-center">
                <img
                  src={happy1}
                  alt="TAVR"
                  className="max-w-[500px] w-full rounded-tl-[40px] shadow-lg"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="py-12 bg-white">
        <Container>
          <Grid container justifyContent="space-between" alignItems="center" className="pb-8">
            <Grid item xs={12} lg={12}>
              <h2 className="text-center text-black text-3xl">
                AI TAVR is an artificial intelligence that reads CT scans to predict the risk of surgical complications, like stroke.
              </h2>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="bg-[#1764c0] py-12">
        <Container>
          <Grid container justifyContent="space-between" alignItems="center" className="pb-8">
            <Grid item xs={12} lg={12}>
              <h4 className="text-center text-white mb-8">
                Connect your institution to our cloud based predictive medicine system
              </h4>
              
              <div className="space-y-4">
                <p className="text-white flex items-center gap-2">
                  <FaCheckCircle className="text-green-500" />
                  AI TAVR doesn't need EMR integration. It is an end to end AI that reads CT scan images.
                </p>
                <p className="text-white flex items-center gap-2">
                  <FaCheckCircle className="text-green-500" />
                  AI TAVR has an API-ready system.
                </p>
                <p className="text-white flex items-center gap-2">
                  <FaCheckCircle className="text-green-500" />
                  AI TAVR has a ready-to-use Web application.
                </p>
                <p className="text-white flex items-center gap-2">
                  <FaCheckCircle className="text-green-500" />
                  AI TAVR can be easily integrated into your PACS or CT processing software.
                </p>
                <p className="text-white">AI TAVR is the first predictive medicine solution for heart surgery and interventional cardiology.</p>
                <p className="text-white">AI TAVR is geared towards value-based care.</p>
                <p className="text-white">Already 10+ hospitals are using AI TAVR.</p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="py-12">
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid 
              item 
              xs={12} 
              lg={12}
              container
              direction="column"
              alignItems="center"
              textAlign="center"
            >
              <h2 className="text-center text-3xl p-2" style={{ color:'black', }}>Our science</h2>
              <Box sx={{ mt: 2 }}>
                <p className="mb-1"><strong>"Deep learning-based prediction of early cerebrovascular events after transcatheter aortic valve replacement."</strong></p>
                <p className="mb-1">More than 1 million data points were used to train the AI TAVR deep learning predictive model.</p>
                <br/>
                <Button 
                  onClick={() => window.location.href = 'https://www.nature.com/articles/s41598-021-98265-5'} 
                  variant="contained" 
                  style={{ borderRadius:30, textTransform:'none', }}
                >
                  Read the article
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>


      <section className="section">
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} lg={12}>
              <h4 className="text-center" style={{ color:'black', }}>They talk about us</h4>
            </Grid>
          </Grid>
          <br/>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid 
              item 
              xs={12} 
              lg={3}
              container
              justifyContent="center"  // Centers content on both small and large screens
            >
              <a href='https://outsellinc.com/emerging-50'
                target="_blank" rel='noreferrer'
              >
                <img
                  alt="Outsell 50 logo"
                  className="img-center img-fluid shadow shadow-lg--hover"
                  src={"https://miro.medium.com/v2/resize:fit:828/format:webp/1*fDtGNxExkjxzGIyhTWnmlA.png"}
                  style={{ width:200, margin:10, }}
                />
              </a>
            </Grid>

            <Grid 
              item 
              xs={12} 
              lg={3}
              container
              justifyContent="center"  // Centers content on both small and large screens
            >
              <a href='https://medcitynews.com/2022/10/a-predictive-analytics-tool-for-strokes'
                target="_blank" rel='noreferrer'
              >
                <img
                  alt="MedCity News logo"
                  className="img-center img-fluid shadow shadow-lg--hover"
                  src={"https://cdn2.hubspot.net/hubfs/5279328/logo-3.png"}
                  style={{ width:200, margin:10, }}
                />
              </a>
            </Grid>

            <Grid 
              item 
              xs={12} 
              lg={3}
              container
              justifyContent="center"  // Centers content on both small and large screens
            >
              <a href='https://ces.tech/Media/New-Product-Launches.aspx'
                target="_blank" rel='noreferrer'
              >
                <img
                  alt="CES Logo"
                  className="img-center img-fluid shadow shadow-lg--hover"
                  src={"https://logowik.com/content/uploads/images/ces-consumer-technology-association5836.jpg"}
                  style={{ width:200, margin:10, }}
                />
              </a>
            </Grid>

            <Grid 
              item 
              xs={12} 
              lg={3}
              container
              justifyContent="center"  // Centers content on both small and large screens
            >
              <a href='https://www.nature.com/articles/s41598-021-98265-5'
                target="_blank" rel='noreferrer'
              >
                <img
                  alt="Nature"
                  className="img-center img-fluid shadow shadow-lg--hover"
                  src={"https://cdn-images-1.medium.com/max/1200/0*MF0JeaQpsxMrhpBc.png"}
                  style={{ width:200, margin:10, }}
                />
              </a>
            </Grid>
          </Grid>
        </Container>
      </section>


      <section className="py-12">
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} lg={12}>
            <h2 className="text-center text-3xl" style={{ color:'black', }}>Partners</h2>
            <br/>
          </Grid>
        </Grid>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="flex justify-center">
            <a href='https://www.healthwildcatters.com'
              target="_blank" rel='noreferrer'>
              <img
                alt="Health Wildcatters"
                className="w-[200px] m-2.5 shadow-lg hover:shadow-xl transition-shadow"
                src={"https://images.squarespace-cdn.com/content/v1/63e15579a08795186bedfb3e/fca393b9-aa3d-4f29-a34a-5818aa6e4ec9/HW+Logo+Horizontal.png?format=1500w"}
              />
            </a>
          </div>
          <div className="flex justify-center">
            <a href='https://www.nvidia.com/en-us/startups/'
              target="_blank" rel='noreferrer'>
              <img
                alt="NVIDIA"
                className="w-[200px] m-2.5 shadow-lg hover:shadow-xl transition-shadow"
                src={"https://www.nvidia.com/content/dam/en-zz/Solutions/about-nvidia/logo-and-brand/01-nvidia-logo-vert-500x200-2c50-d@2x.png"}
              />
            </a>
          </div>
          <div className="flex justify-center">
            <a href='https://aws.amazon.com/'
              target="_blank" rel='noreferrer'>
              <img
                alt="AWS"
                className="w-[200px] m-2.5 shadow-lg hover:shadow-xl transition-shadow"
                src={"https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg"}
              />
            </a>
          </div>
        </div>

      </Container>
      </section>

    <section className="section">
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} lg={12}>
            <div className="sketchfab-embed-wrapper"> 
              <iframe 
                style={{ width:'100%', height: 400 }} 
                title="Heart" 
                frameBorder="0" 
                allowFullScreen 
                xrspatialtracking={true.toString()} 
                executionwhileoutofviewport={true.toString()} 
                executionwhilenotrendered={true.toString()} 
                webshare={true.toString()} 
                src="https://sketchfab.com/models/1f2d649198484ea2a76096bcd3ca1236/embed?autospin=1&autostart=1&ui_hint=0&dnt=1"
              /> 
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>

    <section className="section">
        <Container>
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} lg={12}>
              <h4 className="text-center pb-2">Reach out to our community</h4>
            </Grid>
        </Grid>
        <br/>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>
            <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7053784345089597441" height="1000" width="100%" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
          </Grid>
        </Grid>
        
        <br/>
        <Grid container justifyContent="space-between" alignItems="center">

        <Grid item xs={12} lg={12} textAlign="center">
          <LinkedinFollowCompany
            companyId={69185862}
            counter="top"
            lang="en_US"
          />
        </Grid>

        <br/>
        <br/>
        <br/>

          <Grid item xs={12}>
            <div className="flex justify-center items-center w-full">
              <TwitterFollowButton
                screenName={'MedniscientAI'}
              />
            </div>
          </Grid>

        </Grid>
      </Container>
  </section>

      <FooterCustom />

    </>
  );
};
